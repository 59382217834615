<div id="UpgradeConfirmationDialog">
    <h2 mat-dialog-title>Confirm Data Correction:  {{ companyName }}</h2>
    <mat-dialog-content align="center">
        Are you sure you want to run the PI2 data correction scripts?  
        <br /> <br /> 
        <strong> This cannot be undone.  <br /> 
            If errantly run on a Legacy account, this WILL upgrade the account in an irreversible manner. </strong>
        <br /> <br />
        <mat-form-field>
        <mat-label>Enter the company name</mat-label>
        <input matInput [(ngModel)]="inputCompanyName" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" 
            (click)="migrate()"
            [mat-dialog-close]="true" 
            [disabled]="companyName !== inputCompanyName">
            Migrate
        </button>
    </mat-dialog-actions>
</div>