/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BehavioralAssessmentCto } from '../models/behavioral-assessment-cto';
import { getBehavioralAssessment$Json } from '../fn/behavioral-assessment/get-behavioral-assessment-json';
import { GetBehavioralAssessment$Json$Params } from '../fn/behavioral-assessment/get-behavioral-assessment-json';
import { getBehavioralAssessment$Plain } from '../fn/behavioral-assessment/get-behavioral-assessment-plain';
import { GetBehavioralAssessment$Plain$Params } from '../fn/behavioral-assessment/get-behavioral-assessment-plain';
import { getBehavioralAssessments$Json } from '../fn/behavioral-assessment/get-behavioral-assessments-json';
import { GetBehavioralAssessments$Json$Params } from '../fn/behavioral-assessment/get-behavioral-assessments-json';
import { getBehavioralAssessments$Plain } from '../fn/behavioral-assessment/get-behavioral-assessments-plain';
import { GetBehavioralAssessments$Plain$Params } from '../fn/behavioral-assessment/get-behavioral-assessments-plain';
import { getDeletedBehavioralAssessments$Json } from '../fn/behavioral-assessment/get-deleted-behavioral-assessments-json';
import { GetDeletedBehavioralAssessments$Json$Params } from '../fn/behavioral-assessment/get-deleted-behavioral-assessments-json';
import { getDeletedBehavioralAssessments$Plain } from '../fn/behavioral-assessment/get-deleted-behavioral-assessments-plain';
import { GetDeletedBehavioralAssessments$Plain$Params } from '../fn/behavioral-assessment/get-deleted-behavioral-assessments-plain';
import { updateBehavioralAssessment$Json } from '../fn/behavioral-assessment/update-behavioral-assessment-json';
import { UpdateBehavioralAssessment$Json$Params } from '../fn/behavioral-assessment/update-behavioral-assessment-json';
import { updateBehavioralAssessment$Plain } from '../fn/behavioral-assessment/update-behavioral-assessment-plain';
import { UpdateBehavioralAssessment$Plain$Params } from '../fn/behavioral-assessment/update-behavioral-assessment-plain';

@Injectable({ providedIn: 'root' })
export class BehavioralAssessmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBehavioralAssessment()` */
  static readonly GetBehavioralAssessmentPath = '/behavioralAssessment/{behavioralAssessmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBehavioralAssessment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessment$Plain$Response(params: GetBehavioralAssessment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BehavioralAssessmentCto>> {
    return getBehavioralAssessment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBehavioralAssessment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessment$Plain(params: GetBehavioralAssessment$Plain$Params, context?: HttpContext): Observable<BehavioralAssessmentCto> {
    return this.getBehavioralAssessment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BehavioralAssessmentCto>): BehavioralAssessmentCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBehavioralAssessment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessment$Json$Response(params: GetBehavioralAssessment$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BehavioralAssessmentCto>> {
    return getBehavioralAssessment$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBehavioralAssessment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessment$Json(params: GetBehavioralAssessment$Json$Params, context?: HttpContext): Observable<BehavioralAssessmentCto> {
    return this.getBehavioralAssessment$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BehavioralAssessmentCto>): BehavioralAssessmentCto => r.body)
    );
  }

  /** Path part for operation `updateBehavioralAssessment()` */
  static readonly UpdateBehavioralAssessmentPath = '/behavioralAssessment/{behavioralAssessmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBehavioralAssessment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateBehavioralAssessment$Plain$Response(params: UpdateBehavioralAssessment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BehavioralAssessmentCto>> {
    return updateBehavioralAssessment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBehavioralAssessment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateBehavioralAssessment$Plain(params: UpdateBehavioralAssessment$Plain$Params, context?: HttpContext): Observable<BehavioralAssessmentCto> {
    return this.updateBehavioralAssessment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BehavioralAssessmentCto>): BehavioralAssessmentCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBehavioralAssessment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateBehavioralAssessment$Json$Response(params: UpdateBehavioralAssessment$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<BehavioralAssessmentCto>> {
    return updateBehavioralAssessment$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBehavioralAssessment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateBehavioralAssessment$Json(params: UpdateBehavioralAssessment$Json$Params, context?: HttpContext): Observable<BehavioralAssessmentCto> {
    return this.updateBehavioralAssessment$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BehavioralAssessmentCto>): BehavioralAssessmentCto => r.body)
    );
  }

  /** Path part for operation `getDeletedBehavioralAssessments()` */
  static readonly GetDeletedBehavioralAssessmentsPath = '/behavioralAssessment/{folderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeletedBehavioralAssessments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedBehavioralAssessments$Plain$Response(params: GetDeletedBehavioralAssessments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BehavioralAssessmentCto>>> {
    return getDeletedBehavioralAssessments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeletedBehavioralAssessments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedBehavioralAssessments$Plain(params: GetDeletedBehavioralAssessments$Plain$Params, context?: HttpContext): Observable<Array<BehavioralAssessmentCto>> {
    return this.getDeletedBehavioralAssessments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BehavioralAssessmentCto>>): Array<BehavioralAssessmentCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeletedBehavioralAssessments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedBehavioralAssessments$Json$Response(params: GetDeletedBehavioralAssessments$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BehavioralAssessmentCto>>> {
    return getDeletedBehavioralAssessments$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeletedBehavioralAssessments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeletedBehavioralAssessments$Json(params: GetDeletedBehavioralAssessments$Json$Params, context?: HttpContext): Observable<Array<BehavioralAssessmentCto>> {
    return this.getDeletedBehavioralAssessments$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BehavioralAssessmentCto>>): Array<BehavioralAssessmentCto> => r.body)
    );
  }

  /** Path part for operation `getBehavioralAssessments()` */
  static readonly GetBehavioralAssessmentsPath = '/behavioralAssessment/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBehavioralAssessments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessments$Plain$Response(params?: GetBehavioralAssessments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BehavioralAssessmentCto>>> {
    return getBehavioralAssessments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBehavioralAssessments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessments$Plain(params?: GetBehavioralAssessments$Plain$Params, context?: HttpContext): Observable<Array<BehavioralAssessmentCto>> {
    return this.getBehavioralAssessments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BehavioralAssessmentCto>>): Array<BehavioralAssessmentCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBehavioralAssessments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessments$Json$Response(params?: GetBehavioralAssessments$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BehavioralAssessmentCto>>> {
    return getBehavioralAssessments$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBehavioralAssessments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBehavioralAssessments$Json(params?: GetBehavioralAssessments$Json$Params, context?: HttpContext): Observable<Array<BehavioralAssessmentCto>> {
    return this.getBehavioralAssessments$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BehavioralAssessmentCto>>): Array<BehavioralAssessmentCto> => r.body)
    );
  }

}
