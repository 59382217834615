import { Component, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    isDarkToggleControl = new FormControl(false);
    environmentName: string | undefined | null = '';

    private darkTheme$ = new Subject<boolean>();

    constructor(
        private renderer: Renderer2,
        private readonly configurationService: ConfigurationService
    ) {
        this.addSystemThemeListener();
        this.addToggleControlStreamListener();

        this.darkTheme$.subscribe((darkModeEvent) => {
            this.toggleDarkMode(darkModeEvent);
        });
    }

    ngOnInit(): void {
        this.initBasedOnSystemTheme();
        this.environmentName =
            this.configurationService.configuration.environmentName;
    }

    ngOnDestroy(): void {
        this.darkTheme$.unsubscribe();
    }

    private initBasedOnSystemTheme(): void {
        try {
            const isDark = window.matchMedia(
                '(prefers-color-scheme: dark)'
            ).matches;
            this.isDarkToggleControl.setValue(isDark);
        } catch (error) {
            console.error(
                error,
                'Browser does not support prefers-color-scheme'
            );
        }
    }

    private addSystemThemeListener(): void {
        try {
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', (e: MediaQueryListEvent) =>
                    this.darkTheme$.next(e.matches)
                );
        } catch (error) {
            console.error(
                error,
                'Browser does not support prefers-color-scheme'
            );
        }
    }

    private addToggleControlStreamListener(): void {
        this.isDarkToggleControl.valueChanges.subscribe((x) =>
            this.darkTheme$.next(Boolean(x))
        );
    }

    private toggleDarkMode(isDark: boolean): void {
        const darkClassName = 'dark-theme';
        if (isDark) {
            this.renderer.addClass(document.body, darkClassName);
        } else {
            this.renderer.removeClass(document.body, darkClassName);
        }
    }
}
