<mat-card appearance="outlined" style="max-width: 666px">
    <mat-card-header>
        <mat-card-title>Search Team to Migrate</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="teamSearchForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-grid-list cols="2" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <div style="align-items: start; display: flex; width: 100%">
                        <mat-form-field appearance="fill">
                            <mat-label>Company Guid</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-company-search-input
                            (userSelected)="setCompanyId($event)"
                        ></app-company-search-input>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Team Name</mat-label>
                        <input matInput formControlName="teamName" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="search()">
            Search
        </button>
    </mat-card-actions>
</mat-card>

<div style="margin-top: 5px">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <button
        mat-raised-button
        color="accent"
        *ngIf="searchResults.length > 0"
        style="margin-bottom: 5px"
        (click)="toggleAllTeams()"
    >
        Select All Teams
    </button>
    <div style="max-height: 500px; overflow-y: auto">
        <table
            mat-table
            [dataSource]="searchResults"
            class="mat-elevation-z8"
            *ngIf="searchResults.length > 0"
            style="width: 500px"
            matSort
            (matSortChange)="sortData($event)"
        >
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>Select</th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                        [checked]="element.isChecked"
                        (change)="toggleTeam(element)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="teamName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="teamName">Team Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.myGroupName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ownerId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="ownerId">Team Owner Id</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.userId }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>

<app-user-search
    selectionType="guid"
    userSearchTitle="Search New Team Owner"
    (userSelected)="selectNewOwner($event)"
    style="margin-top: 5px"
></app-user-search>

<div style="margin: 5px auto 20px" *ngIf="showMigrateTeamsButtons">
    <button
        mat-raised-button
        color="accent"
        (click)="migrateSelectedTeams()"
        [disabled]="!enableMigrateButton"
    >
        Migrate Selected Teams
    </button>
</div>
