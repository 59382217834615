import { Component, Input } from '@angular/core';
import { ICodeInfoDeployable } from 'src/app/services/codeinfo.service';

@Component({
    selector: 'app-codeinfo-card',
    templateUrl: './codeinfo-card.component.html',
    styleUrls: ['./codeinfo-card.component.scss']
})
export class CodeInfoCardComponent {
    @Input() deployable!: ICodeInfoDeployable;

    constructor() {}
}
