/* tslint:disable */
/* eslint-disable */
export enum ClassificationType {
  Unknown = 'Unknown',
  Client = 'Client',
  Seller = 'Seller',
  SellerGrantedTrial = 'SellerGrantedTrial',
  Workshop = 'Workshop',
  DynamicDemo = 'DynamicDemo',
  Test = 'Test',
  LegacyDemoAccount = 'LegacyDemoAccount',
  LegacyBusinessAccount = 'LegacyBusinessAccount'
}
