<button
    [disabled]="disabled"
    type="button"
    mat-stroked-button
    color="accent"
    [matTooltipDisabled]="true"
    #clipboardTooltip="matTooltip"
    matTooltip="Copied to Clipboard!"
    (click)="displayTooltip()"
    [cdkCopyToClipboard]="valueToCopy"
    [class]="type"
>
    <mat-icon>content_copy</mat-icon>
</button>
