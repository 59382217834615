import { EventEmitter, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserSearchDialogComponent } from '../user-search-dialog/user-search-dialog.component';

@Component({
    selector: 'app-user-search-input',
    templateUrl: './user-search-input.component.html',
    styleUrls: ['./user-search-input.component.scss']
})
export class UserSearchInputComponent {
    @Input() selectionType: 'guid' | 'long' = 'guid';
    @Output() userSelected = new EventEmitter<string>();

    public selectedId?: string;

    constructor(private readonly dialog: MatDialog) {}

    public openSearchDialog(): void {
        const dialogRef = this.dialog.open(UserSearchDialogComponent, {
            data: {
                selectionType: this.selectionType
            }
        });
        dialogRef.afterClosed().subscribe((result: string) => {
            this.userSelected.emit(result);
        });
    }
}
