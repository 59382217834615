import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import SearchLookupSelectTypeData from 'src/app/shared/interfaces/searchLookupSelectTypeData';
import { CompanySearchInputDialogComponent } from '../company-search-input-dialog/company-search-input-dialog.component';

@Component({
    selector: 'app-company-search-input',
    templateUrl: './company-search-input.component.html',
    styleUrls: ['./company-search-input.component.scss']
})
export class CompanySearchInputComponent {
    @Input() selectionType: 'guid' | 'long' = 'guid';
    @Output() userSelected = new EventEmitter<string>();

    public selectedId?: string;

    constructor(private readonly dialog: MatDialog) {}

    public openSearchDialog(): void {
        const dialogRef = this.dialog.open(CompanySearchInputDialogComponent, {
            data: {
                selectionType: this.selectionType
            } as SearchLookupSelectTypeData,
            width: '80%',
            height: '80%'
        });
        dialogRef.afterClosed().subscribe((result: string) => {
            this.userSelected.emit(result);
        });
    }
}
