<mat-card appearance="outlined" class="dashboard-card">
    <mat-card-header>
        <mat-card-title> Anonymization Status Check </mat-card-title>
    </mat-card-header>

    <mat-card-content class="dashboard-card-content">
        <div class="row">
            <div class="col">
                <mat-divider
                    style="position: relative; margin: 25px 0 25px 0"
                ></mat-divider>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <form [formGroup]="anonCheckForm" novalidate>
                    <mat-card-content>
                        <mat-form-field class="half-width">
                            <input
                                matInput
                                placeholder="CompanyGuid"
                                formControlName="companyId"
                            />
                            <mat-error
                                *ngIf="
                                    anonCheckForm.controls[
                                        'companyId'
                                    ].hasError('required')
                                "
                            >
                                CompanyId is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            mat-raised-button
                            color="primary"
                            type="submit"
                            (click)="onSubmit()"
                        >
                            Submit
                        </button>
                    </mat-card-actions>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <pre
                    style="
                        width: 100%;
                        word-wrap: break-word;
                        white-space: pre-line;
                    "
                    >{{ responseDataStream$ | async }}</pre
                >
                <mat-progress-bar
                    *ngIf="showProgress"
                    mode="indeterminate"
                    style="margin: 50px 0 10px 0"
                ></mat-progress-bar>
            </div>
        </div>
    </mat-card-content>
</mat-card>
