import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfigurationService } from '../shared/services/configuration.service';

export interface ICodeInfoDeployable {
    repositoryName: string;
    repositoryUrl: string;
    readmePath: string;
    deployableName: string;
    deployableType: string;
    deployablePurpose: string;
    maintainingTeam: string;
    primaryMaintainerName: string;
    maintainerSlack: string;
    primaryProjectFilePath: string;
    solutionFilePath: string;
    productionUrl: string;
    packagePublishUrl: string;
    developUrl: string;
    sonarcloudProjectUrl: string;
    smsHealthUrl: string;
    datadogProductionRecentLogsUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class CodeInfoService {
    // private baseUrl = 'https://localhost:5003/api/v1';
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        configurationService: ConfigurationService
    ) {
        this.baseUrl = configurationService.configuration.codeInfoUrl ?? '';
    }

    async status(): Promise<string> {
        return lastValueFrom(this.http.get<string>(this.baseUrl + '/status'));
    }

    deployables(
        hideUnspecified = false,
        team = '',
        repoPrefix = ''
    ): Observable<ICodeInfoDeployable[]> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        // dont set the following if they are empty strings
        const queryParams = new HttpParams()
            .set('hideUnspecified', hideUnspecified)
            .set('team', team)
            .set('repoPrefix', repoPrefix);

        return this.http.get<ICodeInfoDeployable[]>(
            this.baseUrl + '/deployables',
            {
                headers: headers,
                params: queryParams
            }
        );
    }
}
