import { Component, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import {
  MigrateTeamsRequestCto,
    UserCompanyMyGroupCto
} from 'src/app/api/models';
import { DesignTeamService } from 'src/app/api/services';
import {
    ConfirmDialogComponent,
    ConfirmDialogComponentData
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import {
    PermissionsService,
    Permissions
} from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-migrate-team',
    templateUrl: './migrate-team.component.html',
    styleUrls: ['./migrate-team.component.scss']
})
export class MigrateTeamComponent {
    @Input() selectionType: 'guid' | 'long' = 'guid';

    public displayedColumns: string[] = ['select', 'teamName', 'ownerId'];

    public searchResults: UserCompanyMyGroupCto[] = [];

    public teamSearchForm = this.fb.group({
        teamName: [null],
        companyGuid: ['', Validators.required]
    });

    public loading = false;

    public canEdit = false;

    public showMigrateTeamsButtons: boolean = false;

    private selectedNewOwner: string = '';

    constructor(
        private fb: FormBuilder,
        permissionsService: PermissionsService,
        private readonly dialog: MatDialog,
        private readonly designTeamService: DesignTeamService
    ) {
        this.canEdit = permissionsService.hasPermission(
            Permissions.UserOpsWrite
        );
    }

    get teamName(): string | undefined {
        return this.teamSearchForm.controls.teamName.value ?? undefined;
    }

    get companyGuid(): string | undefined {
        return this.teamSearchForm.controls.companyGuid.value ?? undefined;
    }

    get enableMigrateButton(): boolean {
        return (
            this.searchResults.length != 0 &&
            this.searchResults.some((team) => team.isChecked)
        );
    }

    public search(): void {
        if (this.teamSearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.designTeamService
            .getTeams$Json({
                teamName: this.teamName,
                companyGuid: this.companyGuid ?? ''
            })
            .subscribe((teams) => {
                this.loading = false;
                this.searchResults = teams;
                this.teamSearchForm.markAsPristine();
            });
    }

    public reset(): void {
        this.teamSearchForm.reset();
        this.searchResults = new Array<UserCompanyMyGroupCto>();
    }

    public migrateSelectedTeams(): void {
        const selectedTeamNames = this.searchResults
            .filter((team) => team.isChecked)
            .map((team) => team.myGroupName)
            .join(', ');
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                confirmActionText: 'Migrate',
                title: 'Confirm Migration',
                content:
                    'Are you sure you want to migrate teams: ' +
                    selectedTeamNames +
                    ' to the new team owner: ' +
                    this.selectedNewOwner +
                    '?<br /> If the team names have duplicates in the list of teams for the new owner, they will be renamed as "TeamName 1", "TeamName 2", etc.'
            } as ConfirmDialogComponentData
        });

        dialogRef.afterClosed().subscribe((shouldMigrate) => {
            const request: MigrateTeamsRequestCto = {
                groupIdsToMove: this.searchResults
                    .filter((team) => team.isChecked)
                    .map((team) => team.userCompanyMyGroupId.toString()),
                newOwnerGuid: this.selectedNewOwner
            };
            if (shouldMigrate) {
                this.designTeamService
                    .migrateTeams$Json({
                        body: request
                    })
                    .subscribe((response) => {
                        this.dialog.open(ConfirmDialogComponent, {
                            data: {
                                confirmActionText: 'Close',
                                title: 'Migration Result',
                                content: response.errorResponse
                                    ? response.errorResponse
                                    : response.successResponse
                            } as ConfirmDialogComponentData
                        });
                        this.search();
                    });
            }
        });
    }

    public setCompanyId(companyGuid: string): void {
        this.teamSearchForm.controls['companyGuid'].setValue(companyGuid);
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.search();
            event.stopPropagation();
        }
    }

    public selectNewOwner($event: string): void {
        this.selectedNewOwner = $event;
        this.showMigrateTeamsButtons = true;
    }

    public toggleAllTeams(): void {
        // Check if all teams are selected; if so, we'll unselect all.
        const allSelected = this.searchResults.every((team) => team.isChecked);

        for (const team of this.searchResults) {
            team.isChecked = !allSelected;
        }
    }

    public toggleTeam(team: UserCompanyMyGroupCto): void {
        team.isChecked = !team.isChecked;
    }

    public sortData(sort: Sort) {
      const data = this.searchResults.slice();
      if (!sort.active || sort.direction === '') {
          this.searchResults = data;
          return;
      }

      this.searchResults = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
              case 'teamName':
                  return ((a.myGroupName ?? '') < (b.myGroupName ?? '') ? -1 : 1) * (isAsc ? 1 : -1);
              case 'ownerId':
                  return ((a.userId ?? 0) < (b.userId ?? 0) ? -1 : 1) * (isAsc ? 1 : -1);
              default:
                  return 0;
          }
      });
  }
}
