/* tslint:disable */
/* eslint-disable */
export enum PermissionsModuleAccessLevel {
  Unknown = 'Unknown',
  NoAccess = 'NoAccess',
  LimitedAccess = 'LimitedAccess',
  FullAccess = 'FullAccess',
  AllModuleAdmin = 'AllModuleAdmin',
  AllCompanyAdmin = 'AllCompanyAdmin'
}
