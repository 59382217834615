<mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>

<div class="row" style="align-items: center; flex-direction: column">
    <mat-slide-toggle style="margin-top: 20px" [formControl]="toggle"
        >Include Unspecified*</mat-slide-toggle
    >

    <mat-form-field
        style="margin-top: 10px; min-width: 500px"
        appearance="outline"
    >
        <mat-label>Filter/Search</mat-label>
        <input [formControl]="search" matInput placeholder="Search Here" />
        <mat-hint>Filter by name, type, or owner</mat-hint>
    </mat-form-field>

    <mat-card-subtitle style="margin-top: 10px"
        >*You can fix your team's unspecified information by clicking on the
        "EDIT README" button and applying the
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/predictiveindex/documentation/blob/main/docs/pages/Repo%20ReadMe/ReadMeTemplate.md#code-info"
            >Readme Template's Code Info section</a
        >
        to your README file.</mat-card-subtitle
    >
</div>

<mat-divider style="margin-top: 20px; margin-bottom: 20px"></mat-divider>

<div class="container">
    <app-codeinfo-card
        *ngFor="let deployable of filteredDeployableList$ | async"
        class="item"
        [deployable]="deployable"
    ></app-codeinfo-card>
</div>
