import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CircleResponseCto } from '../api/models';

@Component({
    selector: 'app-circle-response-dialog',
    template: `
        <h1 mat-dialog-title>Circle Response</h1>
        <span mat-dialog-content>
            <pre>{{ data.response | json }}</pre>
            <a href="{{ data.url }}" target="_blank" rel="noopener noreferrer"
                >Circle Project</a
            >
        </span>
        <button
            mat-raised-button
            [mat-dialog-close]="true"
            style="margin: 10px 0 10px 0;"
        >
            Close
        </button>
    `
})
export class CircleResponseDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { response: CircleResponseCto; url: string }
    ) {}
}
