import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    private hubConnection!: signalR.HubConnection;

    constructor(public auth: AuthService) {}

    startConnection(url: string): Promise<void> {
        const authToken$ = this.auth.getAccessTokenSilently();
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => firstValueFrom(authToken$)
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        return this.hubConnection.start();
    }

    addHealthCheckReportListener(outputStream$: BehaviorSubject<string>): void {
        this.hubConnection.on('GetHealthCheckReport', (data) => {
            outputStream$.next(JSON.parse(data));
        });
    }

    addAnonymizationCheckReportListener(
        outputStream$: BehaviorSubject<string>
    ): void {
        this.hubConnection.on('GetAnonymizationReport', (data) => {
            outputStream$.next(JSON.parse(data));
        });
    }

    addDisconnectListener(): void {
        this.hubConnection.on('Disconnect', () => {
            console.log('SignalR Disconnected');
        });
    }

    getHealthCheckReport(data: unknown): Promise<unknown> {
        return this.hubConnection.invoke('GetHealthCheckReport', data);
    }

    getAnonymizationCheckReport(data: unknown): Promise<unknown> {
        return this.hubConnection.invoke('GetAnonymizationReport', data);
    }
}
