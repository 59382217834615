import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from './user-profile.component';

@Component({
    selector: 'app-auth0-dialog',
    template: `
        <h1 mat-dialog-title>Auth0 Secret</h1>
        <span mat-dialog-content>
            {{ data.token }}
        </span>
        <button
            mat-raised-button
            [mat-dialog-close]="true"
            style="margin: 10px 0 10px 0;"
        >
            Close
        </button>
    `
})
export class Auth0DialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
