import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { SignalrService } from 'src/app/services/signalr.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';

@Component({
    selector: 'app-anonymization-card',
    templateUrl: './anonymization-card.component.html',
    styleUrls: ['./anonymization-card.component.scss']
})
export class AnonymizationCardComponent {
    responseDataStream$: BehaviorSubject<string> = new BehaviorSubject('');
    showProgress = false;

    anonCheckForm = this.fb.group({
        companyId: ['', Validators.required]
    });

    constructor(
        private fb: UntypedFormBuilder,
        public auth: AuthService,
        private signalR: SignalrService,
        private config: ConfigurationService
    ) {}

    onSubmit(): void {
        const data = this.anonCheckForm.value;
        const body = data['companyId'];
        this.showProgress = true;

        this.signalR
            .startConnection(
                this.config.configuration.healthCheckUrl +
                    '/APAnonmyizationHealthCheckHub'
            )
            .then(() => {
                this.signalR.addAnonymizationCheckReportListener(
                    this.responseDataStream$
                );
                this.signalR.addDisconnectListener();
                this.signalR.getAnonymizationCheckReport(body).then(() => {
                    this.showProgress = false;
                });
            });
    }
}
