import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StrategyPlaygroundSession } from 'src/app/api/models';
import { DesignTeamService, DesignTeamSessionService } from 'src/app/api/services';
import {
    ConfirmDialogComponent,
    ConfirmDialogComponentData
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import {
    PermissionsService,
    Permissions
} from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-delete-team-session',
    templateUrl: './delete-team-session.component.html',
    styleUrls: ['./delete-team-session.component.scss']
})
export class DeleteTeamSessionComponent {
    @Output() userSelected = new EventEmitter<string>();

    @Input() selectionType: 'guid' | 'long' = 'guid';

    public displayedColumns: string[] = ['delete', 'teamSessionName'];

    public searchResults: StrategyPlaygroundSession[] = [];

    public teamSessionSearchForm = this.fb.group({
        teamSessionName: [null],
        companyGuid: ['', Validators.required]
    });

    public loading = false;

    public canEdit = false;

    constructor(
        private fb: FormBuilder,
        private readonly designTeamSessionService: DesignTeamSessionService,
        permissionsService: PermissionsService,
        private readonly dialog: MatDialog
    ) {
        this.canEdit = permissionsService.hasPermission(
            Permissions.UserOpsWrite
        );
    }

    get teamSessionName(): string | undefined {
        return this.teamSessionSearchForm.controls.teamSessionName.value ?? undefined;
    }

    get companyGuid(): string | undefined {
        return this.teamSessionSearchForm.controls.companyGuid.value ?? undefined;
    }

    public search(): void {
        if (this.teamSessionSearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.designTeamSessionService
            .getTeamSessions$Json({
                teamSessionName: this.teamSessionName,
                companyGuid: this.companyGuid ?? ''
            })
            .subscribe((sessions) => {
                this.loading = false;
                this.searchResults = sessions;
                this.teamSessionSearchForm.markAsPristine();
            });
    }

    public reset(): void {
        this.teamSessionSearchForm.reset();
        this.searchResults = new Array<StrategyPlaygroundSession>();
    }

    public deleteTeam(team: StrategyPlaygroundSession): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                confirmActionText: 'Delete',
                title: 'Confirm Deletion',
                content:
                    'Are you sure you want to delete team session: ' +
                    team.sessionName +
                    '?'
            } as ConfirmDialogComponentData
        });

        dialogRef.afterClosed().subscribe((shouldDelete) => {
            if (shouldDelete && team.sessionName) {
                this.designTeamSessionService
                    .deleteTeamSession({
                        companyGuid: this.companyGuid,
                        sessionName: team.sessionName
                    })
                    .subscribe(() => this.search());
            }
        });
    }

    public deleteAllTeamSessions(): void {
        if (
            this.searchResults.length > 0 &&
            this.teamSessionSearchForm.valid &&
            this.teamSessionSearchForm.pristine
        ) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    confirmActionText: 'Delete All Team Sessions',
                    title: 'Confirm Deletion',
                    content:
                        'Are you sure you want to delete all team sessions for this company?'
                } as ConfirmDialogComponentData
            });

            dialogRef.afterClosed().subscribe((shouldDelete) => {
                if (shouldDelete) {
                    this.designTeamSessionService
                        .deleteAllTeamSessions({
                            companyId: this.companyGuid ?? ''
                        })
                        .subscribe(() => this.search());
                }
            });
        }
    }

    public setCompanyId(companyGuid: string): void {
        this.teamSessionSearchForm.controls['companyGuid'].setValue(companyGuid);
    }

    public sortData(sort: Sort) {
        const data = this.searchResults.slice();
        if (!sort.active || sort.direction === '') {
            this.searchResults = data;
            return;
        }

        this.searchResults = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'teamSessionName':
                    return (a.sessionName?? '').localeCompare(b.sessionName ?? '') * (isAsc ? 1 : -1);
                default:
                    return 0;
            }
        });
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.search();
            event.stopPropagation();
        }
    }
}
