<ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
    <button (click)="auth.logout()" mat-icon-button>
        <mat-icon>logout</mat-icon>
    </button>
</ng-container>

<ng-template #loggedOut>
    <button (click)="auth.loginWithRedirect()" mat-icon-button>
        <mat-icon>login</mat-icon>
    </button>
</ng-template>
