import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
    FormBuilder
} from '@angular/forms';
import { BehavioralAssessmentCto, UserCto } from 'src/app/api/models';
import { BehavioralAssessmentService } from 'src/app/api/services';

export const baSearchValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const firstName = control.get('firstName');
    const lastName = control.get('lastName');
    const email = control.get('email');
    const sessionGuid = control.get('sessionGuid');
    const companyGuid = control.get('companyGuid');
    const bsId = control.get('bsId');

    if (
        email?.value ||
        bsId?.value ||
        sessionGuid?.value ||
        companyGuid?.value ||
        (firstName?.value && lastName?.value)
    ) {
        return null;
    }

    if (firstName?.value && !lastName?.value) {
        return { lastNameRequired: true };
    }

    if (!firstName?.value && lastName?.value) {
        return { firstNameRequired: true };
    }

    return { oneOfRequired: true };
};

@Component({
    selector: 'app-behavioral-assessment-search',
    templateUrl: './behavioral-assessment-search.component.html',
    styleUrls: ['./behavioral-assessment-search.component.scss']
})
export class BehavioralAssessmentSearchComponent {
    @Output() userSelected = new EventEmitter<string>();

    @Input() selectionType: 'guid' | 'long' = 'guid';

    public displayedColumns: string[] = [
        'select',
        'email',
        'firstName',
        'lastName',
        'assessmentGuid',
        'bsId',
        'company'
    ];

    public searchResults: UserCto[] = [];

    public baSearchForm = this.fb.group(
        {
            firstName: [null],
            lastName: [null],
            sessionGuid: [null],
            companyGuid: [''],
            email: [null],
            bsId: [null]
        },
        { validators: baSearchValidator }
    );

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private readonly behavioralAssessmentService: BehavioralAssessmentService
    ) {}

    get email(): string | undefined {
        return this.baSearchForm.controls.email.value ?? undefined;
    }

    get firstName(): string | undefined {
        return this.baSearchForm.controls.firstName.value ?? undefined;
    }

    get lastName(): string | undefined {
        return this.baSearchForm.controls.lastName.value ?? undefined;
    }

    get sessionGuid(): string | undefined {
        return this.baSearchForm.controls.sessionGuid.value ?? undefined;
    }

    get companyGuid(): string | undefined {
        return this.baSearchForm.controls.companyGuid.value ?? undefined;
    }

    get bsId(): string | undefined {
        return this.baSearchForm.controls.bsId.value ?? undefined;
    }

    public search(): void {
        if (this.baSearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.behavioralAssessmentService
            .getBehavioralAssessments$Json({
                Email: this.email,
                FirstName: this.firstName,
                LastName: this.lastName,
                SessionGuid: this.sessionGuid,
                CompanyGuid:
                    this.companyGuid != '' ? this.companyGuid : undefined,
                BSID: this.bsId
            })
            .subscribe((assessments) => {
                this.loading = false;
                this.searchResults = assessments;
            });
    }

    public reset(): void {
        this.baSearchForm.reset();
        this.searchResults = new Array<BehavioralAssessmentCto>();
    }

    public selectUser(ba: BehavioralAssessmentCto): void {
        this.userSelected.emit(
            this.selectionType == 'guid'
                ? ba.assessmentGuid?.toString()
                : ba.assessmentSessionId?.toString()
        );
    }

    public setCompanyId(companyGuid: string): void {
        this.baSearchForm.controls['companyGuid'].setValue(companyGuid);
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.search();
            event.stopPropagation();
        }
    }
}
