<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>UserOps (Move Person)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="movePersonForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-error
                *ngIf="
                    movePersonForm.errors?.['surveyUserIdRequired'] &&
                    (movePersonForm.touched || movePersonForm.dirty)
                "
            >
                Survey User ID is required.
            </mat-error>
            <mat-error
                *ngIf="
                    movePersonForm.errors?.['targetCompanyIdRequired'] &&
                    (movePersonForm.touched || movePersonForm.dirty)
                "
            >
                Target Company ID is required.
            </mat-error>
            <mat-error
                *ngIf="
                    movePersonForm.errors?.['targetFolderIdRequired'] &&
                    (movePersonForm.touched || movePersonForm.dirty)
                "
            >
                Target Folder ID is required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Survey User ID</mat-label>
                        <input matInput formControlName="surveyUserId" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Target Company ID</mat-label>
                        <input matInput formControlName="targetCompanyId" />
                    </mat-form-field>
                    <app-company-search-input
                        selectionType="long"
                        (userSelected)="setTargetCompanyId($event)"
                    />
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Target Folder ID</mat-label>
                        <input matInput formControlName="targetFolderId" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div>
                        <mat-label>Is Test Run?</mat-label>
                        <mat-slide-toggle formControlName="isTestRun" />
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="submit()">
            Submit
        </button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>

    <div style="margin-top: 5px">
        <mat-spinner *ngIf="loading"></mat-spinner>

        <form
            [formGroup]="movePersonResponseForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <textarea
                placeholder="Success!"
                formControlName="responseOkMessage"
                *ngIf="!loading && wasResponseSuccessful === true"
                style="
                    display: flex;
                    margin-top: 15px;
                    width: 98%;
                    min-height: 125px;
                "
            >
            </textarea>

            <textarea
                placeholder="An error occurred."
                formControlName="responseErrorMessage"
                *ngIf="!loading && wasResponseSuccessful === false"
                style="
                    display: flex;
                    margin-top: 15px;
                    width: 98%;
                    min-height: 125px;
                "
            >
            </textarea>
        </form>
    </div>
</mat-card>
