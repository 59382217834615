import { ElementRef, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, startWith, Subject } from 'rxjs';
import { CirclePayload, CircleResponseCto } from '../api/models';
import { CircleService } from '../api/services';
import { CircleResponseDialogComponent } from './circle-response-dialog.component';

@Component({
    selector: 'app-ui-automation-self-serve-card',
    templateUrl: './ui-automation-self-serve-card.component.html',
    styleUrls: ['./ui-automation-self-serve-card.component.scss']
})
export class UiAutomationSelfServeCardComponent implements OnInit {
    @ViewChild('suiteInput', { static: true })
    suiteInputRef!: ElementRef<HTMLInputElement>;

    environment = 'dev';
    public selectedTags: string[] = [];

    disableAdvanced = false;

    form = this.fb.group({
        notifySlack: [true],
        updateHistory: [false],
        workflowBuild: [false],
        devTest: [true],
        stageTest: [false],
        workflow: ['dev'],
        suite: [''],
        threads: ['10'],
        notifyHeader: ['The PIT'],
        advancedFilter: [''],
        tagCombination: ['|']
    });

    tagsChanged: Subject<string[]> = new Subject<string[]>();

    public tags: string[] = ['Loading...'];

    filteredTags: Observable<string[]> = this.tagsChanged
        .asObservable()
        .pipe(startWith(this.tags));

    userEmail = 'na';
    fullHeaderText = 'na';

    private tagPrefix = 'tag:';
    private featurePrefix = 'feature:';
    private scenarioPrefix = 'scenario:';

    private cirlceUrl =
        'https://app.circleci.com/pipelines/github/predictiveindex/pi-spa-test';

    constructor(
        private fb: UntypedFormBuilder,
        private circleci: CircleService,
        private dialog: MatDialog,
        public auth: AuthService
    ) {
        this.circleci.getCircleFilters$Json().subscribe((result) => {
            if (!result.tags || !result.features || !result.scenarios) {
                this.tags = [];
            } else {
                this.tags = result.tags
                    .map((tag) => this.tagPrefix + tag)
                    .concat(result.features?.map((x) => this.featurePrefix + x))
                    .concat(
                        result.scenarios?.map((x) => this.scenarioPrefix + x)
                    );
            }
            this.tagsChanged.next(this.filterTags());
        });
        this.tagsChanged.next(this.filterTags());
        this.filteredTags.subscribe((_) => {
            this.updateAdvancedFilter();
        });
        this.form.controls['tagCombination'].valueChanges.subscribe(() => {
            this.updateAdvancedFilter();
        });

        this.form.controls['suite'].valueChanges.subscribe((value) => {
            this.tagsChanged.next(this.filterTags());
        });
    }

    // get the email from the user profile so that we can include an identifier in the Slack Header
    ngOnInit(): void {
        this.auth.user$.subscribe(
            (profile) => (this.userEmail = profile?.email ?? 'unknown')
        );
    }

    onSubmit(): void {
        const body: CirclePayload = {
            branch: 'main',
            parameters: {
                run_workflow_stage_test: this.form.value.workflow === 'stage',
                run_workflow_dev_test: this.form.value.workflow === 'dev',
                run_workflow_build: false,
                test_filter_override: this.form.value.advancedFilter,
                test_threads_override: this.form.value.threads,
                chain_notify_workflow: this.form.value.notifySlack,
                chain_results_archive_workflow: this.form.value.updateHistory,
                notify_header_override: this.formattedHeaderText(
                    this.form.value.notifyHeader
                )
            }
        };

        try {
            this.circleci.circleProxy$Json({ body }).subscribe((result) => {
                this.showCircleResponseDialog(result, this.cirlceUrl);
            });
        } catch (error) {
            console.error(error);
        }
    }

    public selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedTags.push(event.option.viewValue);
        this.form.controls['suite'].setValue('');
        this.suiteInputRef.nativeElement.value = '';
        this.tagsChanged.next(this.filterTags());
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            this.selectedTags.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.form.controls['suite'].setValue('');
        this.suiteInputRef.nativeElement.value = '';
        this.tagsChanged.next(this.filterTags());
    }

    remove(tag: string): void {
        const index = this.selectedTags.indexOf(tag);

        if (index >= 0) {
            this.selectedTags.splice(index, 1);
        }
        this.tagsChanged.next(this.filterTags());
    }

    private updateAdvancedFilter(): void {
        if (this.selectedTags.length > 0) {
            const joinOperator = this.form.controls['tagCombination'].value;

            const filterString = this.selectedTags
                .map((tag) => {
                    if (tag.startsWith(this.tagPrefix)) {
                        return `(Category=${tag.replace(
                            this.tagPrefix + '@',
                            ''
                        )})`;
                    } else if (tag.startsWith(this.featurePrefix)) {
                        return `(FeatureTitle=${tag.replace(
                            this.featurePrefix,
                            ''
                        )})`;
                    } else if (tag.startsWith(this.scenarioPrefix)) {
                        return (
                            '(' +
                            tag
                                .replace(this.scenarioPrefix, '')
                                .replace(/ /g, '&') +
                            ')'
                        );
                    } else return `ERROR: ${tag}`;
                })
                .join(` ${joinOperator} `);

            this.form.controls['advancedFilter'].setValue(filterString);
        } else {
            this.form.controls['advancedFilter'].setValue('');
        }
    }

    private showCircleResponseDialog(
        response: CircleResponseCto,
        url: string
    ): void {
        this.dialog.open(CircleResponseDialogComponent, {
            data: {
                response: response,
                url: url
            }
        });
    }

    private filterTags(): string[] {
        return this.tags.filter(
            (tag) =>
                !this.selectedTags.includes(tag) &&
                (tag
                    .toLowerCase()
                    .indexOf(this.form.controls['suite'].value.toLowerCase()) >=
                    0 ||
                    this.form.controls['suite'].value === '')
        );
    }

    private formattedHeaderText(value: string): string {
        // remove single quotes as these are a problem in CI Slack Orb
        const headerWithoutQuotes = value.replace(/["']/g, '');
        // return with the user name appended
        return headerWithoutQuotes + this.byUserText(this.userEmail);
    }

    private byUserText(value: string): string {
        // remove the domain from the email address
        const emailLocalPart = value.split('@')[0];
        // return in a format ready to append to the header
        return ' by (' + emailLocalPart + ')';
    }
}
