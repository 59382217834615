import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Observable } from 'rxjs';

export interface NotificationMessage {
    level: 'error' | 'warning' | 'info' | 'success';
    message: string;
    hideDelayInMs: number;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private readonly notificationSubject = new Subject<NotificationMessage>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public onNotification: Observable<NotificationMessage> =
        this.notificationSubject.asObservable();

    constructor(snackBar: MatSnackBar) {
        this.onNotification.subscribe((notification) => {
            let cssClass = '';
            switch (notification.level) {
                case 'error':
                    cssClass = 'red-snackbar';
                    break;
                case 'warning':
                    cssClass = 'orange-snackbar';
                    break;
                case 'success':
                    cssClass = 'green-snackbar';
                    break;
            }

            snackBar.open(notification.message, 'Dismiss', {
                duration: notification.hideDelayInMs,
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: [cssClass]
            });
        });
    }

    public showNotification(message: NotificationMessage): void {
        this.notificationSubject.next(message);
    }

    public showInfoNotification(message: string, hideDelayInMs = 2000): void {
        this.showNotification({
            message: message,
            level: 'info',
            hideDelayInMs: hideDelayInMs
        });
    }

    public showWarnNotification(message: string, hideDelayInMs = 2000): void {
        this.showNotification({
            message: message,
            level: 'warning',
            hideDelayInMs: hideDelayInMs
        });
    }

    public showErrorNotification(message: string, hideDelayInMs = 2000): void {
        this.showNotification({
            message: message,
            level: 'error',
            hideDelayInMs: hideDelayInMs
        });
    }

    public showSuccessNotification(
        message: string,
        hideDelayInMs = 2000
    ): void {
        this.showNotification({
            message: message,
            level: 'success',
            hideDelayInMs: hideDelayInMs
        });
    }
}
