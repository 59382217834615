import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/api/services';

export interface UserMoveToCompanyDialogData {
    userId: string;
}

@Component({
    selector: 'app-user-move-to-company-dialog',
    templateUrl: './user-move-to-company-dialog.component.html',
    styleUrls: ['./user-move-to-company-dialog.component.scss']
})
export class UserMoveToCompanyDialogComponent {
    public moveUserForm = this.fb.group({
        companyId: ['', Validators.required],
        deleteExisting: [true]
    });

    constructor(
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<UserMoveToCompanyDialogComponent>,
        private readonly userService: UserService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: UserMoveToCompanyDialogData
    ) {}

    get companyId(): string | undefined {
        return this.moveUserForm.controls.companyId.value ?? undefined;
    }

    get deleteExisting(): boolean | undefined {
        return this.moveUserForm.controls.deleteExisting.value ?? undefined;
    }

    public setCompanyId(companyId: string): void {
        this.moveUserForm.controls.companyId.setValue(companyId);
    }

    public move(): void {
        if (this.moveUserForm.valid) {
            this.userService
                .moveUserToCompany$Json({
                    userId: this.data.userId,
                    newCompanyId: this.companyId ?? '',
                    disablePreviousUserCompany: this.deleteExisting
                })
                .subscribe(() => {
                    this.dialogRef.close();
                });
        }
    }
}
