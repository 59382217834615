<mat-card
    appearance="outlined"
    *ngIf="deployable !== null"
    class="dashboard-card {{ deployable.deployableType }}"
>
    <mat-card-header>
        <mat-card-title>{{ deployable.repositoryName }}</mat-card-title>
        <mat-card-subtitle>
            <strong>Type: </strong>{{ deployable.deployableType }} &nbsp;&nbsp;
            <strong>Deployable: </strong>{{ deployable.deployableName }}
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <ul>
            <li>
                <strong>Deployable Purpose: </strong
                >{{ deployable.deployablePurpose }}
            </li>
            <li>
                <strong>Primary Maintainer Name: </strong
                >{{ deployable.primaryMaintainerName }}
            </li>
            <li>
                <strong>Maintaining Team: </strong
                >{{ deployable.maintainingTeam }}
            </li>
            <li>
                <strong>Readme Path: </strong
                ><a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/predictiveindex/{{
                        deployable.repositoryName
                    }}/blob/main/{{ deployable.readmePath }}"
                    >{{ deployable.readmePath }}</a
                >
            </li>
        </ul>
    </mat-card-content>
    <mat-card-actions>
        <a
            mat-raised-button
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/predictiveindex/{{
                deployable.repositoryName
            }}"
            >GITHUB REPO</a
        >
        <a
            mat-raised-button
            target="_blank"
            rel="noopener noreferrer"
            href="{{ deployable.datadogProductionRecentLogsUrl }}"
            >LOGS</a
        >
        <a
            mat-raised-button
            target="_blank"
            rel="noopener noreferrer"
            href="{{ deployable.developUrl }}"
            >DEVELOP</a
        >
        <a
            mat-raised-button
            target="_blank"
            rel="noopener noreferrer"
            href="{{ deployable.productionUrl }}"
            >PRODUCTION</a
        >
        <a
            mat-raised-button
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/predictiveindex/{{
                deployable.repositoryName
            }}/blob/main/{{ deployable.readmePath }}"
            >EDIT README</a
        >
    </mat-card-actions>
</mat-card>
