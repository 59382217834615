/* tslint:disable */
/* eslint-disable */
export enum ValueMetricsProductType {
  Unknown = 'Unknown',
  Design = 'Design',
  HireHero = 'HireHero',
  Diagnose = 'Diagnose',
  Hire = 'Hire',
  Inspire = 'Inspire',
  Design2 = 'Design2',
  Inspire2 = 'Inspire2',
  Diagnose2 = 'Diagnose2',
  Perform = 'Perform'
}
