/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserCto } from '../../models/user-cto';

export interface GetUsers$Json$Params {
  Email?: string;
  FirstName?: string;
  LastName?: string;
  ApiUserId?: string;
  UserGuid?: string;
  UserId?: number;
  ApiUserCompanyId?: string;
}

export function getUsers$Json(http: HttpClient, rootUrl: string, params?: GetUsers$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserCto>>> {
  const rb = new RequestBuilder(rootUrl, getUsers$Json.PATH, 'get');
  if (params) {
    rb.query('Email', params.Email, {});
    rb.query('FirstName', params.FirstName, {});
    rb.query('LastName', params.LastName, {});
    rb.query('ApiUserId', params.ApiUserId, {});
    rb.query('UserGuid', params.UserGuid, {});
    rb.query('UserId', params.UserId, {});
    rb.query('ApiUserCompanyId', params.ApiUserCompanyId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<UserCto>>;
    })
  );
}

getUsers$Json.PATH = '/user/search';
