<h1 mat-dialog-title>Move User To Company</h1>
<div mat-dialog-content>
    <form [formGroup]="moveUserForm" style="height: 200px">
        <mat-grid-list rowHeight="75px" cols="1" gutterSize="5px">
            <mat-grid-tile>
                <div style="align-items: start; display: flex">
                    <mat-form-field appearance="fill">
                        <mat-label>Company ID</mat-label>
                        <input matInput formControlName="companyId" />
                    </mat-form-field>
                    <app-company-search-input
                        (userSelected)="setCompanyId($event)"
                    ></app-company-search-input>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-checkbox formControlName="deleteExisting"
                    >Disable Existing UserCompany</mat-checkbox
                >
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-button (click)="move()">Move User</button>
</div>
