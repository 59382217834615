import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivate,
    CanActivateChild
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, Subject, take } from 'rxjs';
import { ConfigurationService } from './configuration.service';

/* Also known as auth0 scopes */
export enum Permissions {
    EngineerRead = 'engineer:read',
    EngineerWrite = 'engineer:write',
    Healthcheck = 'ap-healthcheck:subscribe',
    UserOpsRead = 'userops:read',
    UserOpsWrite = 'userops:write',
    AnyRead = 'any:read',
    AnyWrite = 'any:write',
    UpgradeWrite = "upgrade:write"
}

@Injectable({
    providedIn: 'root'
})
export class PermissionsService implements CanActivate, CanActivateChild {
    private usersPermissions: string[] = [];
    private permissionsChanged$ = new Subject<void>();
    private environment: string;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public permissionsChanged = this.permissionsChanged$.asObservable();

    constructor(
        public auth: AuthService,
        configurationService: ConfigurationService
    ) {
        this.auth.getAccessTokenSilently().subscribe((ac) => {
            const token = JSON.parse(window.atob(ac.split('.')[1]));
            this.usersPermissions = token['permissions'];
            if (this.usersPermissions.length > 0) {
                this.permissionsChanged$.next();
            }
        });

        this.environment =
            configurationService.configuration.environmentName || '';
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

    public hasPermission(permission: string | null): boolean {
        if (!permission) return true;

        if (this.usersPermissions.indexOf(permission) > -1) {
            return true;
        }
        return false;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        if (route.data['environmentRestriction']) {
            const restrictions = route.data[
                'environmentRestriction'
            ] as string[];

            if (!restrictions.find((x) => x === this.environment)) {
                return false;
            }
        }
        if (!route.data['permission']) {
            return true;
        }

        if (this.usersPermissions?.length > 0) {
            return this.hasPermission(route.data['permission']);
        }

        return this.permissionsChanged.pipe(
            take(1),
            map(() => this.hasPermission(route.data['permission']))
        );
    }
}
