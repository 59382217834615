<div class="grid-container">
    <h1 style="text-align: center">
        Welcome to The PIT (The Predictive Index Internal Tools)
    </h1>
    <span style="font-size: 17px">
        Here you'll find a collection of tools aimed at UserOps and Engineering.
        These tools are intended to help users in each group take actions within
        the PI software that is not able to be done within the software
        itself.<br /><br />
        Have questions? Want to provide feedback, report a bug, or request a new
        feature? Head over to
        <a
            href="slack://channel?team=T0932C9JT&id=C04HTAA9T5H"
            target="_blank"
            rel="noopener noreferrer"
            >#the-pit-help-and-feedback</a
        >
    </span>
    <!-- To get rid of the recaptcha logo/banner we have to show the below somewhere on the site to comply to the google terms of service -->
    <mat-card-content
        *ngIf="auth.isAuthenticated$ | async"
        style="bottom: 20px; right: 40px; position: absolute"
    >
        <br />
        This site is protected by reCAPTCHA and the Google
        <a
            mat-button
            target="_blank"
            rel="noopener noreferrer"
            color="accent"
            href="https://policies.google.com/privacy"
            >Privacy Policy</a
        >
        and
        <a
            mat-button
            target="_blank"
            rel="noopener noreferrer"
            color="accent"
            href="https://policies.google.com/terms"
            >Terms of Service</a
        >
        apply.
    </mat-card-content>
</div>
