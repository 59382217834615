import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/material.module';
import { AnonymizationCardComponent } from './anonymization-card/anonymization-card.component';
import { HealthCheckCardComponent } from './health-check-card/health-check-card.component';
import { HealthCheckContainerComponent } from './health-check-container/health-check-container.component';

const components = [
    AnonymizationCardComponent,
    HealthCheckContainerComponent,
    HealthCheckCardComponent
];

@NgModule({
    declarations: components,
    exports: components,
    imports: [CommonModule, ReactiveFormsModule, MaterialModule],
    providers: []
})
export class HealthCheckModule {}
