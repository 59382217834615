<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>Company Search</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="companySearchForm"
            novalidate
            (keyup)="$event.keyCode === 13 ? search() : null"
        >
            <mat-error
                *ngIf="
                    companySearchForm.errors?.['oneOfRequired'] &&
                    (companySearchForm.touched || companySearchForm.dirty)
                "
            >
                One of: Name, Company ID, API Company ID or Company Guid are
                required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <input type="name" matInput formControlName="name" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Company ID</mat-label>
                        <input matInput formControlName="id" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Company Guid</mat-label>
                        <input matInput formControlName="guid" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>API Company ID</mat-label>
                        <input matInput formControlName="apiCompanyId" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="search()">
            Search
        </button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>
</mat-card>

<div style="margin-top: 5px">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table
        mat-table
        [dataSource]="searchResults"
        class="mat-elevation-z8"
        *ngIf="searchResults.length > 0"
        style="width: 100%"
    >
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Select</th>
            <td mat-cell *matCellDef="let element">
                <button mat-stroked-button (click)="selectCompany(element)">
                    Select
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Company Id</th>
            <td mat-cell *matCellDef="let element">{{ element.companyId }}</td>
        </ng-container>

        <ng-container matColumnDef="guid">
            <th mat-header-cell *matHeaderCellDef>Company Guid</th>
            <td mat-cell *matCellDef="let element">
                {{ element.companyGuid }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="apiCompanyId">
            <th mat-header-cell *matHeaderCellDef>API Company Id</th>
            <td mat-cell *matCellDef="let element">
                {{ element.apiCompanyId }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
