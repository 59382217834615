import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { CompanyService } from '../../api/services/company.service';


export interface UpgradeCompanyDialogData {
    companyId: string;
    companyName: string;
    inputCompanyName: string;
}

@Component({
    selector: 'app-upgrade-company-dialog',
    templateUrl: './upgrade-company-dialog.component.html',
    styleUrls: ['./upgrade-company-dialog.component.scss']
})
export class UpgradeCompanyDialogComponent implements OnInit, OnDestroy {

    companyId = ''
    companyName = '';
    inputCompanyName = '';

    private destroyed$ = new Subject<void>();

    constructor(
        private readonly companyService: CompanyService,
        private readonly snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            companyName: string;
            companyId: string
        }
    ) { }

    ngOnInit(): void {
        this.companyId = this.data.companyId;
        this.companyName = this.data.companyName;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    migrate(): void {
        this.companyService.executeCompanyUpgrade$Json({
            companyId: this.companyId
        }).subscribe((res) => {
            if (res.ok) {
                this.snackbar.open('Migration initated. Please allow a few minutes to process.', 'Dismiss',
                    {
                        duration: 5000
                    })
            } else {
                this.snackbar.open('An error occur.', 'Dismiss',
                    {
                        duration: 3000
                    })
            }
        });
    }
}
