import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { JobService } from 'src/app/api/services';
import { FolderSelectionOutput } from "../../common/folder-search/folder-search/folder-search.component";

@Component({
    selector: 'app-copy-job',
    templateUrl: './copy-job.component.html',
    styleUrls: ['./copy-job.component.scss']
})
export class CopyJobComponent {
    isLoading = false;

    copyJobForm = this.fb.group({
        destinationCompanyGuid: ['', Validators.required],
        destinationFolderGuid: ['', Validators.required],
        positionGuid: ['', Validators.required],
    });

    statusMessage: string | undefined;

    constructor(
        private fb: FormBuilder,
        private jobService: JobService
    ) {}

    copyJob(): void {
        if (!this.copyJobForm.valid) {
            return;
        }
        this.isLoading = true;
        this.jobService.copyPositionToNewCompanyAsync$Json({
                body: {
                    destinationCompanyGuid: this.copyJobForm.controls.destinationCompanyGuid.value ?? '',
                    destinationFolderGuid: this.copyJobForm.controls.destinationFolderGuid.value ?? '',
                    positionGuid: this.copyJobForm.controls.positionGuid.value ?? '',
                    isTestRun: false
                }
            })
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((result) => {
                if (result) {
                    this.statusMessage = result;
                }
            });
    }

    public setFolder(selectedFolder: FolderSelectionOutput): void {
        this.copyJobForm.controls.destinationCompanyGuid.setValue(selectedFolder.companyGuid);
        this.copyJobForm.controls.destinationFolderGuid.setValue(selectedFolder.folderGuid);
    }
}
