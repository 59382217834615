import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FolderSearchInputDialogComponent } from '../folder-search-input-dialog/folder-search-input-dialog.component';
import { FolderSelectionOutput } from "../folder-search/folder-search.component";

@Component({
    selector: 'app-folder-search-input',
    templateUrl: './folder-search-input.component.html'
})
export class FolderSearchInputComponent {
    @Output() folderSelected = new EventEmitter<FolderSelectionOutput>();

    constructor(private readonly dialog: MatDialog) {}

    public openSearchDialog(): void {
        const dialogRef = this.dialog.open(FolderSearchInputDialogComponent, {
            width: '80%',
            height: '80%'
        });
        dialogRef.afterClosed().subscribe((result: FolderSelectionOutput) => {
            this.folderSelected.emit(result);
        });
    }
}
