<button
    type="button"
    mat-raised-button
    color="primary"
    (click)="openSearchDialog()"
    style="
        border-radius: 0 10px 10px 0;
        height: 51px;
        width: 51px;
        margin-left: 0px;
    "
>
    <mat-icon>search</mat-icon>
</button>
