<mat-card appearance="outlined" class="dashboard-card">
    <mat-card-header>
        <mat-card-title> Health Check </mat-card-title>
    </mat-card-header>

    <mat-card-content class="dashboard-card-content">
        <div class="row">
            <span class="spacer"></span>
            <button (click)="destroyAndReload()" mat-mini-fab>
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <div class="row">
            <div class="col">
                <app-health-check-card
                    *ngIf="isVisible$ | async"
                ></app-health-check-card>
            </div>
        </div>
    </mat-card-content>
</mat-card>
