import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0DialogComponent } from './auth0-dialog.component';

export interface DialogData {
    token: string;
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
    secretToken = '';
    constructor(
        public auth: AuthService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.auth.getAccessTokenSilently().subscribe((token) => {
            this.secretToken = token;
        });
    }

    showTokenDialog(): void {
        this.dialog.open(Auth0DialogComponent, {
            data: {
                token: this.secretToken
            }
        });
    }
}
