/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyCto } from '../models/company-cto';
import { DetailedCompanyCto } from '../models/detailed-company-cto';
import { extendCompanyTimeTrial$Json } from '../fn/company/extend-company-time-trial-json';
import { ExtendCompanyTimeTrial$Json$Params } from '../fn/company/extend-company-time-trial-json';
import { extendCompanyTimeTrial$Plain } from '../fn/company/extend-company-time-trial-plain';
import { ExtendCompanyTimeTrial$Plain$Params } from '../fn/company/extend-company-time-trial-plain';
import { getCompanies$Json } from '../fn/company/get-companies-json';
import { GetCompanies$Json$Params } from '../fn/company/get-companies-json';
import { getCompanies$Plain } from '../fn/company/get-companies-plain';
import { GetCompanies$Plain$Params } from '../fn/company/get-companies-plain';
import { getCompany$Json } from '../fn/company/get-company-json';
import { GetCompany$Json$Params } from '../fn/company/get-company-json';
import { getCompany$Plain } from '../fn/company/get-company-plain';
import { GetCompany$Plain$Params } from '../fn/company/get-company-plain';
import { getCompanyTimeTrial$Json } from '../fn/company/get-company-time-trial-json';
import { GetCompanyTimeTrial$Json$Params } from '../fn/company/get-company-time-trial-json';
import { getCompanyTimeTrial$Plain } from '../fn/company/get-company-time-trial-plain';
import { GetCompanyTimeTrial$Plain$Params } from '../fn/company/get-company-time-trial-plain';
import { TimeTrialCto } from '../models/time-trial-cto';
import { updateCompany$Json } from '../fn/company/update-company-json';
import { UpdateCompany$Json$Params } from '../fn/company/update-company-json';
import { updateCompany$Plain } from '../fn/company/update-company-plain';
import { UpdateCompany$Plain$Params } from '../fn/company/update-company-plain';
import { CompanyUpgradeDetailsCto } from '../models/company-upgrade-details-cto';
import { getCompanyUpgrade$Plain, GetCompanyUpgrade$Plain$Params } from '../fn/company/upgrade/get-company-upgrade-plain';
import { getCompanyUpgrade$Json, GetCompanyUpgrade$Json$Params } from '../fn/company/upgrade/get-company-upgrade-json';
import { executeCompanyUpgrade$Plain, ExecuteCompanyUpgrade$Plain$Params } from '../fn/company/upgrade/execute-company-upgrade-plain';
import { executeCompanyUpgrade$Json, ExecuteCompanyUpgrade$Json$Params } from '../fn/company/upgrade/execute-company-upgrade-json';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompany()` */
  static readonly GetCompanyPath = '/company/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Plain$Response(params: GetCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedCompanyCto>> {
    return getCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Plain(params: GetCompany$Plain$Params, context?: HttpContext): Observable<DetailedCompanyCto> {
    return this.getCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedCompanyCto>): DetailedCompanyCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Json$Response(params: GetCompany$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedCompanyCto>> {
    return getCompany$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Json(params: GetCompany$Json$Params, context?: HttpContext): Observable<DetailedCompanyCto> {
    return this.getCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedCompanyCto>): DetailedCompanyCto => r.body)
    );
  }

  /** Path part for operation `updateCompany()` */
  static readonly UpdateCompanyPath = '/company/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Plain$Response(params: UpdateCompany$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedCompanyCto>> {
    return updateCompany$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Plain(params: UpdateCompany$Plain$Params, context?: HttpContext): Observable<DetailedCompanyCto> {
    return this.updateCompany$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedCompanyCto>): DetailedCompanyCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Json$Response(params: UpdateCompany$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailedCompanyCto>> {
    return updateCompany$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Json(params: UpdateCompany$Json$Params, context?: HttpContext): Observable<DetailedCompanyCto> {
    return this.updateCompany$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailedCompanyCto>): DetailedCompanyCto => r.body)
    );
  }

  /** Path part for operation `getCompanies()` */
  static readonly GetCompaniesPath = '/company/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Plain$Response(params?: GetCompanies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyCto>>> {
    return getCompanies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Plain(params?: GetCompanies$Plain$Params, context?: HttpContext): Observable<Array<CompanyCto>> {
    return this.getCompanies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyCto>>): Array<CompanyCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanies$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Json$Response(params?: GetCompanies$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyCto>>> {
    return getCompanies$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanies$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanies$Json(params?: GetCompanies$Json$Params, context?: HttpContext): Observable<Array<CompanyCto>> {
    return this.getCompanies$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyCto>>): Array<CompanyCto> => r.body)
    );
  }

  /** Path part for operation `extendCompanyTimeTrial()` */
  static readonly ExtendCompanyTimeTrialPath = '/company/{companyId}/extendtimetrial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extendCompanyTimeTrial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCompanyTimeTrial$Plain$Response(params: ExtendCompanyTimeTrial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TimeTrialCto>> {
    return extendCompanyTimeTrial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extendCompanyTimeTrial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCompanyTimeTrial$Plain(params: ExtendCompanyTimeTrial$Plain$Params, context?: HttpContext): Observable<TimeTrialCto> {
    return this.extendCompanyTimeTrial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeTrialCto>): TimeTrialCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extendCompanyTimeTrial$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCompanyTimeTrial$Json$Response(params: ExtendCompanyTimeTrial$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TimeTrialCto>> {
    return extendCompanyTimeTrial$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extendCompanyTimeTrial$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extendCompanyTimeTrial$Json(params: ExtendCompanyTimeTrial$Json$Params, context?: HttpContext): Observable<TimeTrialCto> {
    return this.extendCompanyTimeTrial$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeTrialCto>): TimeTrialCto => r.body)
    );
  }

  /** Path part for operation `getCompanyTimeTrial()` */
  static readonly GetCompanyTimeTrialPath = '/company/{companyId}/timetrial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyTimeTrial$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTimeTrial$Plain$Response(params: GetCompanyTimeTrial$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TimeTrialCto>>> {
    return getCompanyTimeTrial$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyTimeTrial$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTimeTrial$Plain(params: GetCompanyTimeTrial$Plain$Params, context?: HttpContext): Observable<Array<TimeTrialCto>> {
    return this.getCompanyTimeTrial$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimeTrialCto>>): Array<TimeTrialCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyTimeTrial$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTimeTrial$Json$Response(params: GetCompanyTimeTrial$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TimeTrialCto>>> {
    return getCompanyTimeTrial$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyTimeTrial$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyTimeTrial$Json(params: GetCompanyTimeTrial$Json$Params, context?: HttpContext): Observable<Array<TimeTrialCto>> {
    return this.getCompanyTimeTrial$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimeTrialCto>>): Array<TimeTrialCto> => r.body)
    );
  }


  /** Path part for operation `getCompanyUpgrade()` */
  static readonly GetCompanyUpgradePath = '/company/{companyId}/upgrade';

  /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getCompanyUpgrade$Plain()` instead.
     *
     * This method doesn't expect any request body.
     */
  getCompanyUpgrade$Plain$Response(params: GetCompanyUpgrade$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyUpgradeDetailsCto>>> {
    return getCompanyUpgrade$Plain(this.http, this.rootUrl, params, context);
  }

  /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `getCompanyUpgrade$Plain$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
  getCompanyUpgrade$Plain(params: GetCompanyUpgrade$Plain$Params, context?: HttpContext): Observable<Array<CompanyUpgradeDetailsCto>> {
    return this.getCompanyUpgrade$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyUpgradeDetailsCto>>): Array<CompanyUpgradeDetailsCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyUpgrade$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUpgrade$Json$Response(params: GetCompanyUpgrade$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyUpgradeDetailsCto>>> {
    return getCompanyUpgrade$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyUpgrade$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyUpgrade$Json(params: GetCompanyUpgrade$Json$Params, context?: HttpContext): Observable<Array<CompanyUpgradeDetailsCto>> {
    return this.getCompanyUpgrade$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyUpgradeDetailsCto>>): Array<CompanyUpgradeDetailsCto> => r.body)
    );
  }


  /** Path part for operation `executeCompanyUpgrade()` */
  static readonly ExecuteCompanyUpgradePath = '/company/{companyId}/upgrade';

  /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `executeCompanyUpgrade$Plain()` instead.
     *
     * This method doesn't expect any request body.
     */
  executeCompanyUpgrade$Plain$Response(params: ExecuteCompanyUpgrade$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return executeCompanyUpgrade$Plain(this.http, this.rootUrl, params, context);
  }

  /**
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `executeCompanyUpgrade$Plain$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
  executeCompanyUpgrade$Plain(params: ExecuteCompanyUpgrade$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return this.executeCompanyUpgrade$Plain$Response(params, context);
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeCompanyUpgrade$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeCompanyUpgrade$Json$Response(params: ExecuteCompanyUpgrade$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return executeCompanyUpgrade$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeCompanyUpgrade$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeCompanyUpgrade$Json(params: ExecuteCompanyUpgrade$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return this.executeCompanyUpgrade$Json$Response(params, context);
  }
}
