/* tslint:disable */
/* eslint-disable */
export enum ErrorNumber {
  Success = 'Success',
  RequiredCriteriaInvalid = 'RequiredCriteriaInvalid',
  RequiredParameterMissing = 'RequiredParameterMissing',
  ParameterOutOfRange = 'ParameterOutOfRange',
  ParameterMustBeLessThanParameterX = 'ParameterMustBeLessThanParameterX',
  EndpointUnreachable = 'EndpointUnreachable',
  InternalError = 'InternalError',
  EntityNotFound = 'EntityNotFound',
  ParameterIsInvalid = 'ParameterIsInvalid',
  EntityFound = 'EntityFound',
  ParameterMissing = 'ParameterMissing',
  MultipleIdsProvided = 'MultipleIdsProvided',
  RequestedFormatIsNotSupported = 'RequestedFormatIsNotSupported',
  InputJsonIsInvalid = 'InputJSONIsInvalid',
  GenericParameterIsInvalid = 'GenericParameterIsInvalid',
  LanguageRequestedIsInvalid = 'LanguageRequestedIsInvalid',
  ParameterContainsInvalidIds = 'ParameterContainsInvalidIds',
  ParameterContainsInvalidId = 'ParameterContainsInvalidId',
  NoAssociatedPosition = 'NoAssociatedPosition',
  AssessmentNotFound = 'AssessmentNotFound',
  PositionNotCompleted = 'PositionNotCompleted',
  ParameterMustGreaterThanX = 'ParameterMustGreaterThanX',
  ParameterMustBeGreaterThanParameterX = 'ParameterMustBeGreaterThanParameterX',
  AccessDenied = 'AccessDenied',
  LimitExceeded = 'LimitExceeded',
  EntityAlreadyExists = 'EntityAlreadyExists',
  ParameterMustNotBeAnEmptyString = 'ParameterMustNotBeAnEmptyString',
  DownForMaintenance = 'DownForMaintenance',
  RetiredApi = 'RetiredAPI',
  ODataExpressionNotValid = 'ODataExpressionNotValid',
  ODataSelectQueryOptionError = 'ODataSelectQueryOptionError',
  ODataFilterQueryOptionError = 'ODataFilterQueryOptionError',
  ODataSkipQueryOptionError = 'ODataSkipQueryOptionError',
  ODataTopQueryOptionError = 'ODataTopQueryOptionError',
  ODataOrderByParameterError = 'ODataOrderByParameterError',
  ODataFeatureNotSupportedError = 'ODataFeatureNotSupportedError',
  ODataExpressionCantBeInterpreted = 'ODataExpressionCantBeInterpreted',
  ODataSelectQueryOptionMalformedError = 'ODataSelectQueryOptionMalformedError',
  ODataFilterQueryOptionMalformedError = 'ODataFilterQueryOptionMalformedError',
  ODataSkipQueryOptionMalformedError = 'ODataSkipQueryOptionMalformedError',
  ODataTopQueryOptionMalformedError = 'ODataTopQueryOptionMalformedError',
  ODataOrderByParameterMalformedError = 'ODataOrderByParameterMalformedError',
  ODataSearchQueryOptionError = 'ODataSearchQueryOptionError',
  ODataSearchFieldsQueryOptionError = 'ODataSearchFieldsQueryOptionError',
  ParameterValueExists = 'ParameterValueExists',
  UserAlreadyHasInheritedAccess = 'UserAlreadyHasInheritedAccess',
  UserAlreadyHasDirectAccess = 'UserAlreadyHasDirectAccess',
  ConflictFound = 'ConflictFound',
  MyGroupDoesNotExist = 'MyGroupDoesNotExist',
  AccessToFolderIsDenied = 'AccessToFolderIsDenied',
  AssessmentExpired = 'AssessmentExpired',
  AssessmentAlreadyInProgress = 'AssessmentAlreadyInProgress',
  NotAuthorized = 'NotAuthorized',
  TooManyApiRequests = 'TooManyAPIRequests',
  MultipleParametersProvidedOneAllowed = 'MultipleParametersProvidedOneAllowed',
  NoneOfRequiredParametersAreProvided = 'NoneOfRequiredParametersAreProvided',
  EntityUpsertFailure = 'EntityUpsertFailure',
  EntityCouldNotBeArchived = 'EntityCouldNotBeArchived',
  EntityCouldNotBeUnarchived = 'EntityCouldNotBeUnarchived',
  AccountProvisioningUserHasAccess = 'AccountProvisioningUserHasAccess',
  AccountProvisioningAlreadyRegisteredDeal = 'AccountProvisioningAlreadyRegisteredDeal',
  AssessmentTimeAllotedExceeded = 'AssessmentTimeAllotedExceeded',
  ServiceBusReceivedMessageError = 'ServiceBusReceivedMessageError'
}
