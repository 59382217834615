import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { debounceTime, lastValueFrom, Subject, tap } from 'rxjs';
import {
    CodeInfoService,
    ICodeInfoDeployable
} from 'src/app/services/codeinfo.service';
@Component({
    selector: 'app-code-info-list',
    templateUrl: './code-info-list.component.html',
    styleUrls: ['./code-info-list.component.scss']
})
export class CodeInfoListComponent implements OnInit {
    deployableList: ICodeInfoDeployable[] = [];
    filteredDeployableList$ = new Subject<ICodeInfoDeployable[]>();
    search = new UntypedFormControl();
    toggle = new UntypedFormControl();

    inProgress = true;
    initialSearch = '';
    initialToggle = false;

    constructor(
        private codeService: CodeInfoService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.route.queryParams.subscribe((x: Params) => {
            this.initialSearch = x['search'] ?? '';
            this.initialToggle = x['hasunspecified'] ?? false;
        });
    }

    ngOnInit(): void {
        this.search.setValue(this.initialSearch);
        this.toggle.setValue(this.initialToggle);

        // bind a search listener to our filter box
        this.search.valueChanges
            .pipe(
                debounceTime(200),
                tap((_) => this.updateUrl())
            )
            .subscribe((value) => {
                this.onSearchValueChange(value.toLowerCase());
            });

        // bind a fetch listener to our toggle switch
        this.toggle.valueChanges
            .pipe(
                debounceTime(200),
                tap((_) => this.updateUrl())
            )
            .subscribe((includeSpecified) => {
                this.fetchDeployables(!includeSpecified);
                this.search.setValue('');
            });

        if (this.initialSearch != '' || !this.initialToggle) {
            this.fetchDeployables(true, true);
        } else {
            this.fetchDeployables(true);
        }
    }

    onSearchValueChange(value: string): void {
        // a clean observable/(subject) way for the UI to listen to changes to the "array" without ngIf semantics
        this.filteredDeployableList$.next(this.filterDeployables(value));
    }

    private updateUrl(): void {
        const desiredRoute = `/codeinfo?search=${
            this.search.value ?? ''
        }&hasUnspecified=${this.toggle.value ?? false}`;
        this.location.go(encodeURI(desiredRoute.toLowerCase()));
    }

    private filterDeployables(value: string): ICodeInfoDeployable[] {
        return this.deployableList.filter(
            (x) =>
                this.containsValue(x.deployableName, value) ||
                this.containsValue(x.primaryMaintainerName, value) ||
                this.containsValue(x.repositoryName, value) ||
                this.containsValue(x.maintainingTeam, value) ||
                this.containsValue(x.deployableType, value)
            // this.containsValue(x.deployablePurpose, value)
            // add more properties here you want to filter on,
            // the or will short circuit, add them in order of precendence
        );
    }

    private async fetchDeployables(
        hideUnspecified: boolean,
        doInitialFilter = false
    ): Promise<void> {
        this.inProgress = true;
        try {
            const result = await lastValueFrom(
                this.codeService.deployables(hideUnspecified)
            );
            this.deployableList = result;
            this.filteredDeployableList$.next(result);
            if (doInitialFilter) {
                this.onSearchValueChange(this.initialSearch);
            }
        } finally {
            this.inProgress = false;
        }
    }

    private containsValue(haystack: string, needle: string): boolean {
        if (needle == '') {
            return true;
        }

        return haystack.toLowerCase().indexOf(needle) > -1;
    }
}
