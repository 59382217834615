<mat-card appearance="outlined" style="max-width: 666px">
    <mat-card-header>
        <mat-card-title>Search Team Session to Delete</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="teamSessionSearchForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-grid-list cols="2" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <div style="align-items: start; display: flex; width: 100%">
                        <mat-form-field appearance="fill">
                            <mat-label>Company Guid</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-company-search-input
                            (userSelected)="setCompanyId($event)"
                        ></app-company-search-input>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Team Session Name</mat-label>
                        <input matInput formControlName="teamSessionName" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="search()">
            Search
        </button>
        <button
            mat-raised-button
            color="accent"
            (click)="deleteAllTeamSessions()"
            [disabled]="searchResults.length === 0"
        >
            Delete All Team Sessions
        </button>
    </mat-card-actions>
</mat-card>

<div style="margin-top: 5px">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table
        mat-table
        [dataSource]="searchResults"
        class="mat-elevation-z8"
        *ngIf="searchResults.length > 0"
        style="width: 500px"
        matSort
        (matSortChange)="sortData($event)"
    >
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    mat-stroked-button
                    (click)="deleteTeam(element)"
                    *ngIf="canEdit"
                >
                    Delete
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="teamSessionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="teamSessionName">Team Session Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.sessionName }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
