/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CircleFiltersCto } from '../models/circle-filters-cto';
import { circleProxy$Json } from '../fn/circle/circle-proxy-json';
import { CircleProxy$Json$Params } from '../fn/circle/circle-proxy-json';
import { circleProxy$Plain } from '../fn/circle/circle-proxy-plain';
import { CircleProxy$Plain$Params } from '../fn/circle/circle-proxy-plain';
import { CircleResponseCto } from '../models/circle-response-cto';
import { getCircleFilters$Json } from '../fn/circle/get-circle-filters-json';
import { GetCircleFilters$Json$Params } from '../fn/circle/get-circle-filters-json';
import { getCircleFilters$Plain } from '../fn/circle/get-circle-filters-plain';
import { GetCircleFilters$Plain$Params } from '../fn/circle/get-circle-filters-plain';

@Injectable({ providedIn: 'root' })
export class CircleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circleProxy()` */
  static readonly CircleProxyPath = '/aphealthcheck/v1/cicd/selfserve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circleProxy$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  circleProxy$Plain$Response(params?: CircleProxy$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CircleResponseCto>> {
    return circleProxy$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circleProxy$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  circleProxy$Plain(params?: CircleProxy$Plain$Params, context?: HttpContext): Observable<CircleResponseCto> {
    return this.circleProxy$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CircleResponseCto>): CircleResponseCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circleProxy$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  circleProxy$Json$Response(params?: CircleProxy$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CircleResponseCto>> {
    return circleProxy$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circleProxy$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  circleProxy$Json(params?: CircleProxy$Json$Params, context?: HttpContext): Observable<CircleResponseCto> {
    return this.circleProxy$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CircleResponseCto>): CircleResponseCto => r.body)
    );
  }

  /** Path part for operation `getCircleFilters()` */
  static readonly GetCircleFiltersPath = '/aphealthcheck/v1/cicd/filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCircleFilters$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircleFilters$Plain$Response(params?: GetCircleFilters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CircleFiltersCto>> {
    return getCircleFilters$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCircleFilters$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircleFilters$Plain(params?: GetCircleFilters$Plain$Params, context?: HttpContext): Observable<CircleFiltersCto> {
    return this.getCircleFilters$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CircleFiltersCto>): CircleFiltersCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCircleFilters$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircleFilters$Json$Response(params?: GetCircleFilters$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CircleFiltersCto>> {
    return getCircleFilters$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCircleFilters$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCircleFilters$Json(params?: GetCircleFilters$Json$Params, context?: HttpContext): Observable<CircleFiltersCto> {
    return this.getCircleFilters$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CircleFiltersCto>): CircleFiltersCto => r.body)
    );
  }

}
