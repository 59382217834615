<mat-card appearance="outlined" class="dashboard-card">
    <mat-card-header>
        <mat-card-title> UI Testing Self Serve </mat-card-title>
    </mat-card-header>

    <mat-card-content class="dashboard-card-content">
        <div class="row">
            <div class="col">
                <form [formGroup]="form" novalidate>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <mat-radio-group
                            class="radio-group"
                            formControlName="workflow"
                        >
                            <mat-label style="margin: 5px"
                                >CircleCI Workflows</mat-label
                            >
                            <div class="row">
                                <div class="col">
                                    <mat-radio-button
                                        class="radio-group"
                                        value="dev"
                                        >Dev Test</mat-radio-button
                                    >
                                </div>
                                <div class="col">
                                    <mat-radio-button
                                        class="radio-group"
                                        value="stage"
                                        >Stage Test</mat-radio-button
                                    >
                                </div>
                            </div>
                        </mat-radio-group>
                        <mat-divider></mat-divider>
                        <mat-radio-group class="radio-group">
                            <mat-label style="margin: 5px"
                                >Test Setting Overrides</mat-label
                            >
                            <div class="row">
                                <div class="col">
                                    <mat-radio-group
                                        formControlName="tagCombination"
                                    >
                                        <mat-label
                                            >Treat selections as logical:
                                        </mat-label>
                                        <mat-radio-button
                                            style="margin-right: 10px"
                                            value="|"
                                            >OR</mat-radio-button
                                        >
                                        <mat-radio-button
                                            style="margin-right: 10px"
                                            value="&"
                                            >AND</mat-radio-button
                                        >
                                    </mat-radio-group>
                                    <mat-form-field
                                        class="full-width"
                                        style="margin-top: 10px; width: 600px"
                                        appearance="fill"
                                    >
                                        <mat-label
                                            >Tags, Features, and
                                            Scenarios</mat-label
                                        >

                                        <mat-chip-grid
                                            #chipList
                                            color="primary"
                                        >
                                            <mat-chip-row
                                                *ngFor="let tag of selectedTags"
                                                [removable]="true"
                                                (removed)="remove(tag)"
                                                color="primary"
                                                selected
                                            >
                                                {{ tag }}
                                                <mat-icon matChipRemove
                                                    >cancel</mat-icon
                                                >
                                            </mat-chip-row>
                                            <input
                                                placeholder="Categories"
                                                #suiteInput
                                                formControlName="suite"
                                                [matAutocomplete]="auto"
                                                [matChipInputFor]="chipList"
                                                [matChipInputAddOnBlur]="false"
                                                (matChipInputTokenEnd)="
                                                    add($event)
                                                "
                                                value=""
                                            />
                                        </mat-chip-grid>
                                        <mat-autocomplete
                                            #auto="matAutocomplete"
                                            (optionSelected)="selected($event)"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let tag of filteredTags
                                                        | async
                                                "
                                                [value]="tag"
                                            >
                                                {{ tag }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <h2>Or Provide Your Own Filter</h2>
                                    <mat-form-field
                                        class="full-width"
                                        style="margin-top: 10px; width: 600px"
                                        appearance="fill"
                                    >
                                        <mat-label>Advanced Filter</mat-label>
                                        <textarea
                                            placeholder="Provide your own filter instead of using the Tags selection."
                                            matInput
                                            aria-label="Advanced Filter"
                                            formControlName="advancedFilter"
                                        ></textarea>
                                        <mat-hint
                                            >Example: (i.e
                                            "(Category=Mailinator) &amp;
                                            (Category=Hire2)") see
                                            <a
                                                href="https://docs.google.com/document/d/1D8En3RKcAizhMH-q5MV3CPC0CtDYWmvOqiAWDz7AIP8/edit#bookmark=id.85oerdjziny8"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                >this document</a
                                            >
                                            for more info.</mat-hint
                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field
                                        class="half-width"
                                        style="margin-top: 10px"
                                        appearance="fill"
                                    >
                                        <mat-label>Threads</mat-label>
                                        <input
                                            matInput
                                            aria-label="Threads"
                                            formControlName="threads"
                                        />
                                        <mat-hint>Enter 1-10</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-radio-group>
                        <mat-divider></mat-divider>
                        <mat-radio-group class="radio-group">
                            <mat-label style="margin: 5px"
                                >Chained Processes</mat-label
                            >
                            <div class="row">
                                <div class="col">
                                    <mat-checkbox
                                        class="radio-group"
                                        formControlName="notifySlack"
                                        >Notify Slack</mat-checkbox
                                    >
                                </div>
                                <div class="col">
                                    <mat-checkbox
                                        class="radio-group"
                                        formControlName="updateHistory"
                                        >Update Test History (Only for Full
                                        Suites)</mat-checkbox
                                    >
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>
                                        <mat-form-field
                                            class="full-width"
                                            style="
                                                margin-top: 10px;
                                                width: 600px;
                                            "
                                            appearance="fill"
                                        >
                                            <mat-label>Slack Header</mat-label>
                                            <input
                                                matInput
                                                aria-label="State"
                                                formControlName="notifyHeader"
                                            />
                                            <mat-hint
                                                >Identifier in Slack Notify
                                                Post</mat-hint
                                            >
                                        </mat-form-field>
                                    </label>
                                </div>
                            </div>
                        </mat-radio-group>
                        <mat-card-actions>
                            <button
                                mat-raised-button
                                color="primary"
                                [disabled]="!form.valid"
                                type="submit"
                                (click)="onSubmit()"
                            >
                                Submit
                            </button>
                        </mat-card-actions>
                    </mat-card-content>
                </form>
            </div>
        </div>
    </mat-card-content>
</mat-card>
