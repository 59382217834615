import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehavioralAssessmentCto } from 'src/app/api/models';
import { BehavioralAssessmentService } from 'src/app/api/services';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
    PermissionsService,
    Permissions
} from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-edit-behavioral-assessment',
    templateUrl: './edit-behavioral-assessment.component.html',
    styleUrls: ['./edit-behavioral-assessment.component.scss']
})
export class EditBehavioralAssessmentComponent implements OnInit {
    editAssessmentForm: FormGroup;

    public canEdit = false;

    public assessment?: BehavioralAssessmentCto;

    public loading = false;

    private selectedId = '';

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly behavioralAssessmentService: BehavioralAssessmentService,
        permissionsService: PermissionsService,
        private readonly router: Router,
        private readonly notificationService: NotificationService
    ) {
        this.canEdit = permissionsService.hasPermission(
            Permissions.UserOpsWrite
        );

        this.editAssessmentForm = this.fb.group({
            email: [
                { value: null, disabled: true },
                [Validators.required, Validators.email]
            ],
            firstName: [{ value: null, disabled: true }, Validators.required],
            lastName: [{ value: null, disabled: true }, Validators.required],
            assessmentGuid: [{ value: null, disabled: true }],
            surveyUserId: [{ value: null, disabled: true }],
            surveyUserGuid: [{ value: null, disabled: true }],
            analystEmail: [{ value: null, disabled: true }],
            analystFirstName: [{ value: null, disabled: true }],
            analystLastName: [{ value: null, disabled: true }],
            companyName: [{ value: null, disabled: true }],
            bsId: [{ value: null, disabled: true }],
            assessmentDate: [{ value: null, disabled: true }],
            companyId: [
                {
                    value: null,
                    disabled: true
                }
            ],
            companyGuid: [
                {
                    value: null,
                    disabled: true
                }
            ],
            isDeleted: [{ value: null, disabled: !this.canEdit }],
            isPISurveyDeleted: [{ value: null, disabled: !this.canEdit }],
            isSurveyUserDeleted: [{ value: null, disabled: !this.canEdit }],
            userCompanies: this.fb.array([])
        });
    }

    get email(): string | undefined {
        return this.editAssessmentForm.controls['email'].value ?? undefined;
    }

    get bsId(): string | undefined {
        return this.editAssessmentForm.controls['bsId'].value ?? undefined;
    }

    get assessmentDate(): string | undefined {
        return (
            this.editAssessmentForm.controls['assessmentDate'].value ??
            undefined
        );
    }

    get analystEmail(): string | undefined {
        return (
            this.editAssessmentForm.controls['analystEmail'].value ?? undefined
        );
    }

    get surveyUserId(): string | undefined {
        return (
            this.editAssessmentForm.controls['surveyUserId'].value ?? undefined
        );
    }
    get surveyUserGuid(): string | undefined {
        return (
            this.editAssessmentForm.controls['surveyUserGuid'].value ??
            undefined
        );
    }
    get assessmentGuid(): string | undefined {
        return (
            this.editAssessmentForm.controls['assessmentGuid'].value ??
            undefined
        );
    }
    get companyId(): string | undefined {
        return this.editAssessmentForm.controls['companyId'].value ?? undefined;
    }
    get companyGuid(): string | undefined {
        return (
            this.editAssessmentForm.controls['companyGuid'].value ?? undefined
        );
    }

    get isDeleted(): boolean | undefined {
        return this.editAssessmentForm.controls['isDeleted'].value ?? undefined;
    }

    get isSurveyUserDeleted(): boolean | undefined {
        return (
            this.editAssessmentForm.controls['isSurveyUserDeleted'].value ??
            undefined
        );
    }

    get isPISurveyDeleted(): boolean | undefined {
        return (
            this.editAssessmentForm.controls['isPISurveyDeleted'].value ??
            undefined
        );
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.selectedId = params['id'];
            if (this.selectedId) {
                this.populateForm();
            }
        });
    }

    public save(): void {
        if (this.editAssessmentForm.valid) {
            this.behavioralAssessmentService
                .updateBehavioralAssessment$Json({
                    behavioralAssessmentId: this.selectedId,
                    IsDeleted: this.isDeleted,
                    SurveyIsDeleted: this.isPISurveyDeleted,
                    SurveyUserIsDeleted: this.isSurveyUserDeleted
                })
                .subscribe({
                    next: (assessment) => {
                        this.resetForm(assessment);
                        this.notificationService.showSuccessNotification(
                            'Save Successful'
                        );
                    },
                    error: (errorMessage) => {
                        this.notificationService.showErrorNotification(
                            'Error Saving: ' + errorMessage
                        );
                    }
                });
        }
    }

    public back(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    public reset(): void {
        this.populateForm();
    }

    public selectAssessment(id: string): void {
        this.selectedId = id;
        this.router.navigate([this.selectedId], { relativeTo: this.route });
    }

    private populateForm(): void {
        this.loading = true;
        this.behavioralAssessmentService
            .getBehavioralAssessment$Json({
                behavioralAssessmentId: this.selectedId
            })
            .subscribe((assessment) => this.resetForm(assessment));
    }

    private resetForm(assessment: BehavioralAssessmentCto): void {
        this.assessment = assessment;
        this.editAssessmentForm.reset({
            email: this.assessment?.surveyUser?.email,
            firstName: this.assessment?.surveyUser?.firstName,
            lastName: this.assessment?.surveyUser?.lastName,
            surveyUserId: this.assessment?.surveyUser?.surveyUserId,
            surveyUserGuid: this.assessment?.surveyUser?.surveyUserGuid,
            companyName: this.assessment?.surveyUser?.company?.name,
            companyId: this.assessment?.surveyUser?.company?.companyId,
            companyGuid: this.assessment?.surveyUser?.company?.companyGuid,
            isSurveyUserDeleted: this.assessment.surveyUser?.isArchived,
            isDeleted: this.assessment?.isDeleted,
            isPISurveyDeleted: this.assessment?.surveyIsDeleted,
            analystFirstName: this.assessment.analystFirstName,
            analystLastName: this.assessment.analystLastName,
            analystEmail: this.assessment.analystEmail,
            assessmentGuid: this.assessment.assessmentGuid,
            bsId: this.assessment.bsid,
            assessmentDate: this.assessment.assessmentDate
        });

        this.loading = false;
    }
}
