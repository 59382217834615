import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { CognitiveAssessmentService } from 'src/app/api/services';

@Component({
    selector: 'app-cognitive-assessment-reset',
    templateUrl: './cognitive-assessment-reset.component.html',
    styleUrls: ['./cognitive-assessment-reset.component.scss']
})
export class CognitiveAssessmentResetComponent {
    isLoading = false;

    cogResetForm = this.fb.group({
        companyId: ['', Validators.required],
        email: ['', Validators.required]
    });

    statusMessage: string | undefined;

    constructor(
        private fb: FormBuilder,
        private cognitiveAssessmentService: CognitiveAssessmentService
    ) {}

    resetCognitiveAttempts(): void {
        if (!this.cogResetForm.valid) {
            return;
        }
        this.isLoading = true;
        // eslint-disable-next-line prettier/prettier
        this.cognitiveAssessmentService.resetCognitiveAttempts$Plain
            ({
                companyId: this.cogResetForm.controls.companyId.value ?? '',
                email: this.cogResetForm.controls.email.value ?? ''
            })
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((status) => {
                this.statusMessage = status;
            });
    }

    public setCompanyId(companyGuid: string): void {
        this.cogResetForm.controls.companyId.setValue(companyGuid);
    }
}
