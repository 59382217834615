import { ValueMetricsProductType } from '../api/models/value-metrics-product-type';

export class Constants {
    static Environment = {
        Development: 'Development',
        Staging: 'Staging',
        Production: 'Production'
    };

    // PI.Accounts.Provisioning.CompanyAccountProvisioningProductType
    static apProductType = {
        unknown: 0,
        design: 1,
        hireHero: 2,
        diagnose: 3,
        design2: 4,
        diagnose2: 5,
        inspire: 6,
        inspire2: 7,
        perform: 9
    };

    static recaptchaSiteKeys = new Map<string, string>([
        ['Local', '6LfE_kYaAAAAAMhCjT2zix6Xvl3VkEHIcZIxSfab'],
        [
            Constants.Environment.Development,
            '6LfE_kYaAAAAAMhCjT2zix6Xvl3VkEHIcZIxSfab'
        ],
        [
            Constants.Environment.Staging,
            '6LeFdvMUAAAAACFCzlZYe6pBRc9o8-N8AKRlIUdh'
        ],
        [
            Constants.Environment.Production,
            '6LeFdvMUAAAAACFCzlZYe6pBRc9o8-N8AKRlIUdh'
        ]
    ]);

    static apProductTypes = [
        { name: 'Hire2.0', type: Constants.apProductType.hireHero },
        { name: 'Design', type: Constants.apProductType.design },
        { name: 'Design2.0', type: Constants.apProductType.design2 },
        { name: 'Diagnose', type: Constants.apProductType.diagnose },
        { name: 'Diagnose2.0', type: Constants.apProductType.diagnose2 },
        { name: 'Inspire', type: Constants.apProductType.inspire },
        { name: 'Inspire2', type: Constants.apProductType.inspire2 },
        { name: 'Perform', type: Constants.apProductType.perform }
    ];

    static vmProductTypes = [
        { name: 'Hire', type: ValueMetricsProductType.Hire },
        { name: 'Hire2.0', type: ValueMetricsProductType.HireHero },
        { name: 'Design', type: ValueMetricsProductType.Design },
        { name: 'Design2.0', type: ValueMetricsProductType.Design2 },
        { name: 'Diagnose', type: ValueMetricsProductType.Diagnose },
        { name: 'Inspire', type: ValueMetricsProductType.Inspire },
        { name: 'Inspire2', type: ValueMetricsProductType.Inspire2 },
        { name: 'Diagnose2', type: ValueMetricsProductType.Diagnose2 },
        { name: 'Perform', type: ValueMetricsProductType.Perform }
    ];

    constructor() {}
}
