import { Component } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { UserOpsService } from 'src/app/api/services';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
    selector: 'app-move-person',
    templateUrl: './move-person.component.html',
    styleUrls: ['./move-person.component.scss']
})
export class UseropsMovePersonComponent {
    public wasResponseSuccessful: boolean | undefined = undefined;

    public movePersonForm = this.fb.group({
        surveyUserId: ['', Validators.required],
        targetCompanyId: ['', Validators.required],
        targetFolderId: ['', Validators.required],
        isTestRun: [false, Validators.required]
    });

    public movePersonResponseForm = this.fb.group({
        responseOkMessage: [''],
        responseErrorMessage: ['']
    });

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private readonly notificationService: NotificationService,
        private readonly useropsService: UserOpsService
    ) {}

    get surveyUserId(): string | undefined {
        return this.movePersonForm.controls.surveyUserId.value ?? undefined;
    }

    get targetCompanyId(): string | undefined {
        return this.movePersonForm.controls.targetCompanyId.value ?? undefined;
    }

    get targetFolderId(): string | undefined {
        return this.movePersonForm.controls.targetFolderId.value ?? undefined;
    }

    get isTestRun(): boolean | undefined {
        return this.movePersonForm.controls.isTestRun.value ?? undefined;
    }

    public submit(): void {
        if (this.movePersonForm.invalid) {
            return;
        }

        this.wasResponseSuccessful = undefined;
        const surveyUserIdAsInteger =
            this.surveyUserId !== undefined
                ? parseInt(this.surveyUserId, 10)
                : -1;
        const targetCompanyIdAsInteger =
            this.targetCompanyId !== undefined
                ? parseInt(this.targetCompanyId, 10)
                : -1;
        const targetFolderIdAsInteger =
            this.targetFolderId !== undefined
                ? parseInt(this.targetFolderId, 10)
                : -1;
        const notificationMessagePrefix =
            'UserOps > "Move Person"' + (this.isTestRun ? ' test run' : '');
        this.movePersonForm.controls;

        this.useropsService
            .movePersonAsync({
                body: {
                    surveyUserId: surveyUserIdAsInteger,
                    targetCompanyId: targetCompanyIdAsInteger,
                    targetFolderId: targetFolderIdAsInteger,
                    isTestRun: this.isTestRun
                }
            })
            .subscribe({
                next: () => {
                    this.wasResponseSuccessful = true;

                    this.notificationService.showSuccessNotification(
                        notificationMessagePrefix + ' succeeded.',
                        5000
                    );

                    const okResponseMessage =
                        notificationMessagePrefix +
                        ' succeeded:\n\n' +
                        `Survey user #${this.surveyUserId} was successfully moved.`;
                    this.movePersonResponseForm.controls.responseOkMessage.setValue(
                        okResponseMessage
                    );
                },
                error: (error) => {
                    this.wasResponseSuccessful = false;

                    this.notificationService.showErrorNotification(
                        notificationMessagePrefix + ' failed.',
                        20000
                    );

                    const maxErrorLinesToDisplay = 1;
                    const errorLinesToDisplay: string[] = [];
                    const errorLines = error.error.split(/\r?\n/);
                    errorLines.find(
                        (x: string) => (
                            x != null &&
                                x !== undefined &&
                                x.length > 0 &&
                                errorLinesToDisplay.push(x),
                            errorLinesToDisplay.length >= maxErrorLinesToDisplay
                        )
                    );
                    const errorResponseMessage =
                        notificationMessagePrefix +
                        ' failed:\n\n' +
                        errorLinesToDisplay.join('\n');
                    this.movePersonResponseForm.controls.responseErrorMessage.setValue(
                        errorResponseMessage
                    );
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }

    public setTargetCompanyId(companyId: string): void {
        this.movePersonForm.controls['targetCompanyId'].setValue(companyId);
    }

    public reset(): void {
        this.movePersonForm.reset();
        this.movePersonResponseForm.reset();
        this.wasResponseSuccessful = undefined;
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.submit();
            event.stopPropagation();
        }
    }
}
