/* eslint-disable dot-notation */
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/app/constants/constants';
import { SignalrService } from '../../../services/signalr.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { HealthCheckFormData } from '../models/health-check-form';
import { IReCaptchaInstance } from '../models/IReCaptchaInstance';

/**
 * See comments in app.module.ts about recaptcha limitations
 */
declare global {
    const grecaptcha: IReCaptchaInstance;

    interface Window {
        grecaptcha: IReCaptchaInstance;
    }
}

@Component({
    selector: 'app-health-check-card',
    templateUrl: './health-check-card.component.html',
    styleUrls: ['./health-check-card.component.scss']
})
export class HealthCheckCardComponent implements OnInit {
    healthCheckForm = this.fb.group({
        product: [Constants.apProductType.hireHero.toString()],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        company: [null, Validators.required],
        email: [null, Validators.required]
    });

    productTypes = Constants.apProductTypes;
    showProgress = false;
    responseDataStream$: BehaviorSubject<string>;

    private environment = 'Development';

    constructor(
        public auth: AuthService,
        public signalr: SignalrService,
        private fb: UntypedFormBuilder,
        private config: ConfigurationService
    ) {
        this.responseDataStream$ = new BehaviorSubject(
            JSON.stringify({ status: 'Ready.' })
        );

        this.environment = this.config.configuration.environmentName ?? '';
    }

    ngOnInit(): void {
        this.generateRandomValues();
    }

    onSubmit(): void {
        this.onFormDataSubmit(this.healthCheckForm.value);
    }

    generateRandomValues(): void {
        const companyName = `fake-${this.makeRandomName()}`;
        const lastName = `fake-${this.makeRandomName().slice(-12)}`;
        const firstName = `fake-${this.makeRandomName().slice(-12)}`;
        const email = `${lastName}@${companyName}.com`;

        this.healthCheckForm.controls['company'].setValue(companyName);
        this.healthCheckForm.controls['email'].setValue(email);
        this.healthCheckForm.controls['firstName'].setValue(firstName);
        this.healthCheckForm.controls['lastName'].setValue(lastName);
    }

    /**
     * Returns a v4 uuid
     * Make sure to prepend anything generated with this with `fake` so it is ignored for tracking purposes.
     */
    private makeRandomName(): string {
        return self.crypto.randomUUID();
    }

    private onFormDataSubmit(event: HealthCheckFormData): void {
        const siteKey = Constants.recaptchaSiteKeys.get(this.environment);
        if (siteKey === undefined) {
            throw new Error(
                'Invalid Environment Selected, are your site keys correctly mapped?'
            );
        }

        grecaptcha
            .execute(siteKey, { action: 'validate_captcha' })
            .then((token) => {
                this.signalr
                    .startConnection(
                        this.config.configuration.healthCheckUrl +
                            '/APHealthCheckHub'
                    )
                    .then(() => {
                        this.addListener(this.responseDataStream$);
                        this.sendPayload(event, token);
                    })
                    .catch((err) =>
                        console.log('Error while starting connection: ' + err)
                    );
            });
    }

    private sendPayload(event: HealthCheckFormData, token: string): void {
        this.showProgress = true;
        this.signalr
            .getHealthCheckReport(this.buildHealthCheckPayload(event, token))
            .then(() => {
                this.showProgress = false;
            });
    }

    private addListener(outputStream$: BehaviorSubject<string>): void {
        this.signalr.addHealthCheckReportListener(outputStream$);
        this.signalr.addDisconnectListener();
    }

    private buildHealthCheckPayload(
        formData: HealthCheckFormData,
        token: string
    ): unknown {
        const companyName = formData.company;
        const email = formData.email;
        const first = formData.firstName;
        const last = formData.lastName;
        const product = +formData.product;
        const isAutoLogin = false;
        const isMUPD = true;
        const provisioningUser = { first, last };

        const createNewCompany = true;
        const synchronouslyProvisionCompany = false;
        const captchaResponse = token;
        return {
            params: {
                captchaResponse,
                companyName,
                createNewCompany,
                isAutoLogin,
                isMUPD,
                name: provisioningUser,
                product,
                synchronouslyProvisionCompany,
                userEmail: email
            }
        };
    }
}
