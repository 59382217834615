import { Component, EventEmitter, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { Router } from '@angular/router';
import { FolderCto } from 'src/app/api/models';
import { FolderService } from 'src/app/api/services';

export const folderSearchValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const name = control.get('companyName');
    const guid = control.get('companyGuid');
    const id = control.get('companyId');
    const apiCompanyId = control.get('apiCompanyId');

    if (name?.value || guid?.value || id?.value || apiCompanyId?.value) {
        return null;
    }

    return { oneOfRequired: true };
};

export interface FolderSelectionOutput {
    companyGuid: string;
    folderGuid: string;
}

@Component({
    selector: 'app-folder-search',
    templateUrl: './folder-search.component.html'
})
export class FolderSearchComponent {
    @Output() folderSelected = new EventEmitter<FolderSelectionOutput>();

    public displayedColumns: string[] = [
        'select',
        'folderName',
        'folderGuid',
        'companyName',
        'companyGuid',
        'companyId',
        'apiCompanyId'
    ];

    public searchResults: FolderCto[] = [];

    public folderSearchForm = this.fb.group(
        {
            companyName: [null],
            companyGuid: [null],
            companyId: [null],
            apiCompanyId: [null],
            folderName: [null],
            folderGuid: [null]
        },
        { validators: folderSearchValidator }
    );

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private readonly folderService: FolderService,
        private readonly router: Router
    ) {}

    get companyName(): string | undefined {
        return this.folderSearchForm.controls.companyName.value ?? undefined;
    }

    get companyGuid(): string | undefined {
        return this.folderSearchForm.controls.companyGuid.value ?? undefined;
    }

    get companyId(): number | undefined {
        return this.folderSearchForm.controls.companyId.value ?? undefined;
    }

    get apiCompanyId(): string | undefined {
        return this.folderSearchForm.controls.apiCompanyId.value ?? undefined;
    }

    get folderName(): string | undefined {
        return this.folderSearchForm.controls.folderName.value ?? undefined;
    }

    get folderGuid(): string | undefined {
        return this.folderSearchForm.controls.folderGuid.value ?? undefined;
    }

    public search(): void {
        if (this.folderSearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.folderService
            .getFolders$Json({
                CompanyName: this.companyName,
                CompanyGuid: this.companyGuid,
                CompanyId: this.companyId,
                ApiCompanyId: this.apiCompanyId,
                FolderName: this.folderName,
                FolderGuid: this.folderGuid
            })
            .subscribe((folders) => {
                this.loading = false;
                this.searchResults = folders;
            });
    }

    public reset(): void {
        this.folderSearchForm.reset();
        this.searchResults = new Array<FolderCto>();
    }

    public selectFolder(folderSelected: FolderSelectionOutput): void {
        this.folderSelected.emit(folderSelected);
    }
}
