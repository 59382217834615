<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>Copy Job</mat-card-title>
    </mat-card-header>
    <form class="move-job-form" [formGroup]="copyJobForm">
        <mat-form-field appearance="fill">
            <mat-label>Source Position Guid</mat-label>
            <input matInput formControlName="positionGuid" />
        </mat-form-field>
        <div style="align-items: start; display: flex; width: 100%">
            <mat-form-field appearance="fill">
                <mat-label>Destination Company Guid</mat-label>
                <input matInput formControlName="destinationCompanyGuid" />
            </mat-form-field>
            <app-folder-search-input
                (folderSelected)="setFolder($event)"
            ></app-folder-search-input>
        </div>
        <div style="align-items: start; display: flex; width: 100%">
            <mat-form-field appearance="fill">
                <mat-label>Destination Folder Guid</mat-label>
                <input matInput formControlName="destinationFolderGuid" />
            </mat-form-field>
            <app-folder-search-input
                (folderSelected)="setFolder($event)"
            ></app-folder-search-input>
        </div>
    </form>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    <p *ngIf="statusMessage">
        {{ statusMessage }}
    </p>
    <mat-card-actions>
        <button
            mat-flat-button
            color="primary"
            (click)="copyJob()"
        >
            Submit
        </button>
    </mat-card-actions>
</mat-card>
