import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyCto } from 'src/app/api/models';
import { CompanyService } from 'src/app/api/services';

export const companySearchValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const name = control.get('name');
    const guid = control.get('guid');
    const id = control.get('id');
    const apiCompanyId = control.get('apiCompanyId');

    if (name?.value || guid?.value || id?.value || apiCompanyId?.value) {
        return null;
    }

    return { oneOfRequired: true };
};

@Component({
    selector: 'app-company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent {
    @Output() companySelected = new EventEmitter<string>();

    @Input() selectionType: 'guid' | 'long' = 'guid';

    public displayedColumns: string[] = [
        'select',
        'name',
        'guid',
        'id',
        'apiCompanyId'
    ];

    public searchResults: CompanyCto[] = [];

    public companySearchForm = this.fb.group(
        {
            name: [null],
            guid: [null],
            id: [null],
            apiCompanyId: [null]
        },
        { validators: companySearchValidator }
    );

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private readonly companyService: CompanyService,
        private readonly router: Router
    ) {}

    get name(): string | undefined {
        return this.companySearchForm.controls.name.value ?? undefined;
    }

    get guid(): string | undefined {
        return this.companySearchForm.controls.guid.value ?? undefined;
    }

    get id(): number | undefined {
        return this.companySearchForm.controls.id.value ?? undefined;
    }

    get apiCompanyId(): string | undefined {
        return this.companySearchForm.controls.apiCompanyId.value ?? undefined;
    }

    public search(): void {
        if (this.companySearchForm.invalid) {
            return;
        }
        this.loading = true;
        this.companyService
            .getCompanies$Json({
                Name: this.name,
                CompanyGuid: this.guid,
                CompanyId: this.id,
                ApiCompanyId: this.apiCompanyId
            })
            .subscribe((companies) => {
                this.loading = false;
                this.searchResults = companies;
            });
    }

    public reset(): void {
        this.companySearchForm.reset();
        this.searchResults = new Array<CompanyCto>();
    }

    public selectCompany(company: CompanyCto): void {
        this.companySelected.emit(
            this.selectionType == 'guid'
                ? company.companyGuid
                : company.companyId?.toString()
        );
    }
}
