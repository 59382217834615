<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>Behavioral Assessment Search</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="baSearchForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-error
                *ngIf="
                    baSearchForm.errors?.['oneOfRequired'] &&
                    (baSearchForm.touched || baSearchForm.dirty)
                "
            >
                One of: Email, Session Guid, or First + Last Name are required.
            </mat-error>
            <mat-error
                *ngIf="
                    baSearchForm.errors?.['firstNameRequired'] &&
                    (baSearchForm.touched || baSearchForm.dirty)
                "
            >
                If providing last name, then first name is required.
            </mat-error>
            <mat-error
                *ngIf="
                    baSearchForm.errors?.['lastNameRequired'] &&
                    (baSearchForm.touched || baSearchForm.dirty)
                "
            >
                If providing first name, then last name is required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="firstName" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="lastName" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Session Guid</mat-label>
                        <input matInput formControlName="sessionGuid" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <div style="align-items: start; display: flex; width: 100%">
                        <mat-form-field appearance="fill">
                            <mat-label>Company Guid</mat-label>
                            <input matInput formControlName="companyGuid" />
                        </mat-form-field>
                        <app-company-search-input
                            (userSelected)="setCompanyId($event)"
                        ></app-company-search-input>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>BSID</mat-label>
                        <input matInput formControlName="bsId" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="search()">
            Search
        </button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>
</mat-card>

<div style="margin-top: 5px">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table
        mat-table
        [dataSource]="searchResults"
        class="mat-elevation-z8"
        *ngIf="searchResults.length > 0"
        style="width: 100%"
    >
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Select</th>
            <td mat-cell *matCellDef="let element">
                <button mat-stroked-button (click)="selectUser(element)">
                    Select
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.firstName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.lastName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element">
                {{ element.company.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="bsId">
            <th mat-header-cell *matHeaderCellDef>BSID</th>
            <td mat-cell *matCellDef="let element">
                {{ element.bsid }}
            </td>
        </ng-container>

        <ng-container matColumnDef="assessmentGuid">
            <th mat-header-cell *matHeaderCellDef>
                Behavioral Assessment Guid
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.assessmentGuid }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
