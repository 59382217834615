import { Pipe, PipeTransform } from '@angular/core';

/**
 * Includes Pipe
 *
 * Searches an array for a specified term. Returns true if found
 * and false if not.
 *
 * Usage: `<div *ngIf="moduleArray | includes: 'design'"></div>`
 *
 * @param search {unknown[]} - Array of items to search
 * @param term {unknown} - Item being searched for
 * @return boolean
 */
@Pipe({
    name: 'includes'
})
export class IncludesPipe implements PipeTransform {
    transform(search: unknown[], term: unknown): boolean {
        return search.includes(term);
    }
}
