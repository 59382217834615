<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>{{ userSearchTitle }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="userSearchForm"
            novalidate
            (keyup)="$event.keyCode === 13 ? search() : null"
        >
            <mat-error
                *ngIf="
                    userSearchForm.errors?.['oneOfRequired'] &&
                    (userSearchForm.touched || userSearchForm.dirty)
                "
            >
                One of: Email, User ID, User Guid, Api User ID, Api User Company
                ID or First + Last Name are required.
            </mat-error>
            <mat-error
                *ngIf="
                    userSearchForm.errors?.['firstNameRequired'] &&
                    (userSearchForm.touched || userSearchForm.dirty)
                "
            >
                If providing last name, then first name is required.
            </mat-error>
            <mat-error
                *ngIf="
                    userSearchForm.errors?.['lastNameRequired'] &&
                    (userSearchForm.touched || userSearchForm.dirty)
                "
            >
                If providing first name, then last name is required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="firstName" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="lastName" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>User Guid</mat-label>
                        <input matInput formControlName="userGuid" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>User ID</mat-label>
                        <input matInput formControlName="userId" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>API User ID</mat-label>
                        <input matInput formControlName="apiUserId" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>API User Company ID</mat-label>
                        <input matInput formControlName="apiUserCompanyId" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="search()">
            Search
        </button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>
</mat-card>

<div style="margin-top: 5px">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table
        mat-table
        [dataSource]="searchResults"
        class="mat-elevation-z8"
        *ngIf="searchResults.length > 0"
        style="width: 100%"
    >
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Select</th>
            <td mat-cell *matCellDef="let element">
                <button mat-stroked-button (click)="selectUser(element)">
                    Select
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element">
                {{ element.primaryCompany.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="userGuid">
            <th mat-header-cell *matHeaderCellDef>User Guid</th>
            <td mat-cell *matCellDef="let element">{{ element.userGuid }}</td>
        </ng-container>

        <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef>User ID</th>
            <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
