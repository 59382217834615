import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeTrialExtensionRequestCto } from 'src/app/api/models';
import { CompanyService } from 'src/app/api/services';
import { Constants } from 'src/app/constants/constants';

@Component({
    selector: 'app-company-extend-time-trial',
    templateUrl: './company-extend-time-trial.component.html',
    styleUrls: ['./company-extend-time-trial.component.scss']
})
export class CompanyExtendTimeTrialComponent {
    companyExtendTimeTrialForm = this.fb.group({
        product: [Constants.apProductType.hireHero.toString()],
        daysToExtend: [null, Validators.required]
    });
    productTypes = Constants.vmProductTypes;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public companyData: TimeTrialExtensionRequestCto,
        private readonly dialogRef: MatDialogRef<CompanyExtendTimeTrialComponent>,
        private fb: UntypedFormBuilder,
        private companyService: CompanyService
    ) {}

    public extendTrial(): void {
        if (this.companyExtendTimeTrialForm.valid) {
            this.companyData.daysToExtendBy =
                this.companyExtendTimeTrialForm.get('daysToExtend')?.value;
            this.companyData.productType =
                this.companyExtendTimeTrialForm.controls['product']?.value;
            this.companyService
                .extendCompanyTimeTrial$Plain$Response({
                    companyId: this.companyData.companyId ?? '',
                    body: this.companyData
                })
                .subscribe(() => {
                    this.dialogRef.close();
                });
        }
    }
}
