import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IpitFrontEndSettings } from 'src/app/api/models';
import { FrontEndSettingsService } from 'src/app/api/services';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private configurationInternal!: IpitFrontEndSettings;

    constructor(private readonly settingsService: FrontEndSettingsService) {}

    public get configuration(): IpitFrontEndSettings {
        return this.configurationInternal;
    }

    public async load(): Promise<IpitFrontEndSettings> {
        if (!this.configuration) {
            this.configurationInternal = await firstValueFrom(
                this.settingsService.getSettings$Json()
            );
        }
        return this.configuration;
    }
}
