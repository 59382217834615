<mat-card-content class="dashboard-card-content">
    <div class="row">
        <div class="col">
            <mat-divider
                *ngIf="!showProgress"
                style="position: relative; margin: 25px 0 25px 0"
            ></mat-divider>
            <mat-progress-bar
                *ngIf="showProgress"
                mode="indeterminate"
                style="position: relative; margin: 25px 0 25px 0"
            ></mat-progress-bar>
        </div>
    </div>

    <form [formGroup]="healthCheckForm" novalidate>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>Product</mat-label>
                        <mat-select
                            [value]="healthCheckForm.controls['product'].value"
                            formControlName="product"
                        >
                            <mat-option
                                *ngFor="let product of productTypes"
                                value="{{ product.type }}"
                                >{{ product.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>First Name</mat-label>
                        <input
                            matInput
                            placeholder="First name"
                            formControlName="firstName"
                        />
                        <mat-error
                            *ngIf="
                                healthCheckForm.controls['firstName'].hasError(
                                    'required'
                                )
                            "
                        >
                            First name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>Last Name</mat-label>
                        <input
                            matInput
                            placeholder="Last name"
                            formControlName="lastName"
                        />
                        <mat-error
                            *ngIf="
                                healthCheckForm.controls['lastName'].hasError(
                                    'required'
                                )
                            "
                        >
                            Last name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>Company</mat-label>
                        <input
                            matInput
                            placeholder="Company"
                            formControlName="company"
                        />
                        <mat-error
                            *ngIf="
                                healthCheckForm.controls['company'].hasError(
                                    'required'
                                )
                            "
                        >
                            Company name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>Email</mat-label>
                        <input
                            matInput
                            placeholder="Email"
                            formControlName="email"
                        />
                        <mat-error
                            *ngIf="
                                healthCheckForm.controls['email'].hasError(
                                    'required'
                                )
                            "
                        >
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button
                style="margin: 5px"
                mat-button
                mat-raised-button
                (click)="generateRandomValues()"
            >
                Generate Random Values
            </button>
            <button
                mat-raised-button
                color="primary"
                type="submit"
                (click)="onSubmit()"
            >
                Submit
            </button>
        </mat-card-actions>
    </form>

    <pre style="width: 100%; word-wrap: break-word; white-space: pre-line">{{
        responseDataStream$ | async | json
    }}</pre>
</mat-card-content>
