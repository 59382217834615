import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SearchLookupSelectTypeData from 'src/app/shared/interfaces/searchLookupSelectTypeData';
import { FolderSelectionOutput } from "../folder-search/folder-search.component";

@Component({
    selector: 'app-folder-search-input-dialog',
    templateUrl: './folder-search-input-dialog.component.html'
})
export class FolderSearchInputDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public selectionType: SearchLookupSelectTypeData,
        private readonly dialogRef: MatDialogRef<FolderSearchInputDialogComponent>
    ) {}

    selectFolder(selectedFolder: FolderSelectionOutput): void {
        this.dialogRef.close(selectedFolder);
    }
}
