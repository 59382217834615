/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FolderCto } from '../models/folder-cto';
import { getFolders$Json } from '../fn/folder/get-folders-json';
import { GetFolders$Json$Params } from '../fn/folder/get-folders-json';
import { getFolders$Plain } from '../fn/folder/get-folders-plain';
import { GetFolders$Plain$Params } from '../fn/folder/get-folders-plain';

@Injectable({ providedIn: 'root' })
export class FolderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFolders()` */
  static readonly GetFoldersPath = '/folder/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolders$Plain$Response(params?: GetFolders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FolderCto>>> {
    return getFolders$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolders$Plain(params?: GetFolders$Plain$Params, context?: HttpContext): Observable<Array<FolderCto>> {
    return this.getFolders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FolderCto>>): Array<FolderCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolders$Json$Response(params?: GetFolders$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FolderCto>>> {
    return getFolders$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFolders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolders$Json(params?: GetFolders$Json$Params, context?: HttpContext): Observable<Array<FolderCto>> {
    return this.getFolders$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FolderCto>>): Array<FolderCto> => r.body)
    );
  }

}
