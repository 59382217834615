<mat-toolbar class="app-toolbar" color="primary">
    <img src="/assets/pit-logo.png" style="margin-right: 10px" />
    <mat-icon class="mr-8">brightness_5</mat-icon>
    <mat-slide-toggle
        [formControl]="isDarkToggleControl"
        class="mr-8"
        style="margin-right: 5px; margin-left: 5px"
    >
    </mat-slide-toggle>
    <mat-icon>bedtime</mat-icon>

    <span class="spacer"></span>
    <app-user-profile></app-user-profile>
    <app-auth-button></app-auth-button>
    <span style="position: absolute; margin: auto 50%">{{
        environmentName
    }}</span>
</mat-toolbar>
