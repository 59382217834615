<h1 mat-dialog-title>Extend Company Time Trial</h1>
<div mat-dialog-content>
    <form [formGroup]="companyExtendTimeTrialForm" novalidate>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <mat-label>Product</mat-label>
                        <mat-select formControlName="product">
                            <mat-option
                                *ngFor="let product of productTypes"
                                [value]="product.type"
                                >{{ product.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input
                            type="number"
                            matInput
                            placeholder="# Days to Extend"
                            formControlName="daysToExtend"
                        />
                        <mat-error
                            *ngIf="
                                companyExtendTimeTrialForm.controls[
                                    'daysToExtend'
                                ].hasError('required')
                            "
                        >
                            # of Days to Extend is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-button (click)="extendTrial()">Extend Time Trial</button>
</div>
