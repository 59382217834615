import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-health-check-container',
    templateUrl: './health-check-container.component.html',
    styleUrls: ['./health-check-container.component.scss']
})
export class HealthCheckContainerComponent {
    isVisible$ = new BehaviorSubject(true);

    constructor() {}

    /**
     * Start over with a fresh instance of the child component every time the env is changed.
     * Nicely cleans up the signalR output and ensures none of the form values are reused
     */
    destroyAndReload(): void {
        this.isVisible$.next(false);
        setTimeout(() => {
            this.isVisible$.next(true);
        }, 1);
    }
}
