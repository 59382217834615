import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {
    @Input() content: string | undefined = '';

    @Input() type: 'circle' | 'flatLeft' = 'flatLeft';

    @ViewChild('clipboardTooltip') clipboardTooltip!: MatTooltip;

    get valueToCopy(): string {
        return this.content ?? '';
    }

    get disabled(): boolean {
        return this.valueToCopy === '';
    }

    public displayTooltip(): void {
        this.clipboardTooltip.disabled = false;
        this.clipboardTooltip.show();
        setTimeout(() => {
            this.clipboardTooltip.disabled = true;
        }, 1000);
    }
}
