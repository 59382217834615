import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehavioralAssessmentCto } from 'src/app/api/models';
import { BehavioralAssessmentService } from 'src/app/api/services';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
    selector: 'app-restore-archived-assessments',
    templateUrl: './restore-archived-assessments.component.html',
    styleUrls: ['./restore-archived-assessments.component.scss']
})
export class RestoreArchivedAssessmentsComponent {
    public wasResponseSuccessful: boolean | undefined = undefined;

    public searchResults: BehavioralAssessmentCto[] = [];

    public restoreCompleted = false;

    public successfullyRestoreList: string[] = [];
    public failedToRestoreList: string[] = [];

    public displayedColumns: string[] = [
        'email',
        'firstName',
        'lastName',
        'assessmentGuid',
        'bsId',
        'company',
        'restore'
    ];

    public restoreTableDisplayedColumns: string[] = [
        'surveyUserGuid'
    ]

    public restoreArchivedAssessmentsForm = this.fb.group({
        folderId: ['', Validators.required],
        restoreAssessments: [true]
    });

    public movePersonResponseForm = this.fb.group({
        responseOkMessage: [''],
        responseErrorMessage: ['']
    });

    public loading = false;

    public saving = false;

    constructor(
        private fb: FormBuilder,
        private behavioralAssessmentService: BehavioralAssessmentService,
        private notificationService: NotificationService
    ) {}

    get folderId(): string | undefined {
        return (
            this.restoreArchivedAssessmentsForm.controls.folderId.value ??
            undefined
        );
    }

    public submit(): void {

        this.restoreCompleted = false;
        if (this.restoreArchivedAssessmentsForm.invalid) {
            return;
        }

        this.loading = true;

        this.wasResponseSuccessful = undefined;
        const folderIdAsInteger =
            this.folderId !== undefined ? parseInt(this.folderId, 10) : -1;

        this.behavioralAssessmentService
            .getDeletedBehavioralAssessments$Json({
                folderId: folderIdAsInteger
            })
            .subscribe((assessments) => {
                this.loading = false;
                this.searchResults = assessments;
            });
    }

    public reset(): void {
        this.restoreArchivedAssessmentsForm.reset();
        this.movePersonResponseForm.reset();
        this.wasResponseSuccessful = undefined;
        this.searchResults = [];
        this.successfullyRestoreList = [];
        this.failedToRestoreList = [];
        this.restoreCompleted = false;
        this.saving = false;
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.submit();
            event.stopPropagation();
        }
    }

    public saveChanges(): void {
        this.saving = true;
        const assessmentsToRestore = this.searchResults.filter(assesment => assesment.restore);      

        this.restoreAssessments(assessmentsToRestore);
    }

    private restoreAssessments(deletedAssessments: BehavioralAssessmentCto[]): void {
        deletedAssessments.forEach((deletedAssessment) => {
            this.behavioralAssessmentService
                .updateBehavioralAssessment$Json({
                    behavioralAssessmentId: deletedAssessment.assessmentGuid?.toString() ?? '',
                    IsDeleted: false,
                    SurveyIsDeleted: false,
                    SurveyUserIsDeleted: false
                })
                .subscribe({
                    next: (assessment) => {
                        this.successfullyRestoreList.push(deletedAssessment.assessmentGuid ?? '');
                    },
                    error: (errorMessage) => {
                        this.failedToRestoreList.push(deletedAssessment.assessmentGuid ?? '');
                    }
                });
        });

        this.restoreCompleted = true;
        this.saving = false;
    }
}
