import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogComponentData {
    title: string;
    content: string;
    confirmActionText: string;
    id?: string;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    settings: ConfirmDialogComponentData;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: ConfirmDialogComponentData
    ) {
        this.settings = data;
    }
}
