<span *ngIf="auth.user$ | async as user" style="padding-right: 15px">{{
    user.email
}}</span>

<span *ngIf="auth.isAuthenticated$ | async" style="padding-right: 5px">
    <button (click)="showTokenDialog()" mat-mini-fab>
        <mat-icon>key</mat-icon>
    </button>
</span>

<span style="padding-right: 5px">
    <a
        href="https://docs.google.com/document/d/1CH8vc4LVJVCuOB2sfLBciXl0N9RALzsgmEDObytiUmY"
        target="_blank"
        rel="noopener noreferrer"
        mat-mini-fab
    >
        <mat-icon>question_mark</mat-icon>
    </a>
</span>
