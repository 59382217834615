import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cognitive-assessment-search',
    templateUrl: './cognitive-assessment-search.component.html',
    styleUrls: ['./cognitive-assessment-search.component.scss']
})
export class CognitiveAssessmentSearchComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
