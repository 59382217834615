import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SearchLookupSelectTypeData from 'src/app/shared/interfaces/searchLookupSelectTypeData';

@Component({
    selector: 'app-company-search-input-dialog',
    templateUrl: './company-search-input-dialog.component.html',
    styleUrls: ['./company-search-input-dialog.component.scss']
})
export class CompanySearchInputDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public selectionType: SearchLookupSelectTypeData,
        private readonly dialogRef: MatDialogRef<CompanySearchInputDialogComponent>
    ) {}

    selectCompany(companyId: string): void {
        this.dialogRef.close(companyId);
    }
}
