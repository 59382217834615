import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { from, lastValueFrom, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (req.url.startsWith(environment.apiUrl)) {
            return from(this.handle(req, next));
        }

        return next.handle(req);
    }

    async handle(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Promise<HttpEvent<unknown>> {
        const token = await lastValueFrom(this.auth.getAccessTokenSilently());

        // Apply the headers
        req = req.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        // Also handle errors globally
        return await lastValueFrom(
            next.handle(req).pipe(
                tap({
                    next: (x) => x,
                    error: (err) => {
                        // Handle this err
                        console.error(
                            `Error performing request, status code = ${err.status}`
                        );
                    }
                })
            )
        );
    }
}
