<mat-sidenav-container style="height: calc(100vh - 65px)">
    <mat-sidenav
        mode="side"
        opened
        [disableClose]="true"
        fixedInViewport="true"
        fixedTopGap="65"
        style="width: 250px"
    >
        <mat-nav-list>
            <div *ngFor="let linkgroup of allowedLinks | async">
                <div mat-subheader *ngIf="linkgroup.name">
                    {{ linkgroup.name }}
                </div>
                <mat-divider *ngIf="linkgroup.name"></mat-divider>
                <a
                    mat-list-item
                    [routerLink]="link.route"
                    *ngFor="let link of linkgroup.links"
                    ><mat-icon style="padding-right: 5px">{{
                        link.icon
                    }}</mat-icon>
                    {{ link.text }}</a
                >
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="padding: 5px"
        ><ng-content></ng-content
    ></mat-sidenav-content>
</mat-sidenav-container>
