/* tslint:disable */
/* eslint-disable */
export enum SoftwareModule {
  Unknown = 'Unknown',
  Hire = 'Hire',
  Design = 'Design',
  Diagnose = 'Diagnose',
  Inspire = 'Inspire',
  Perform = 'Perform'
}
