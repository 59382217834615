<mat-card appearance="outlined" style="max-width: 1000px">
    <mat-card-header>
        <mat-card-title>Cognitive Assessment Reset</mat-card-title>
    </mat-card-header>
    <form class="cog-reset-form" [formGroup]="cogResetForm">
        <div style="align-items: start; display: flex; width: 100%">
            <mat-form-field appearance="fill">
                <mat-label>Company Guid</mat-label>
                <input matInput formControlName="companyId" />
            </mat-form-field>
            <app-company-search-input
                (userSelected)="setCompanyId($event)"
            ></app-company-search-input>
        </div>
        <mat-form-field>
            <mat-label>Survey User Email</mat-label>
            <input formControlName="email" matInput placeholder="Email" />
        </mat-form-field>
    </form>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    <p *ngIf="statusMessage">
        {{ statusMessage }}
    </p>
    <mat-card-actions>
        <button
            mat-flat-button
            color="primary"
            (click)="resetCognitiveAttempts()"
        >
            Submit
        </button>
    </mat-card-actions>
</mat-card>
