import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import SearchLookupSelectTypeData from 'src/app/shared/interfaces/searchLookupSelectTypeData';

@Component({
    selector: 'app-user-search-dialog',
    templateUrl: './user-search-dialog.component.html',
    styleUrls: ['./user-search-dialog.component.scss']
})
export class UserSearchDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public selectionType: SearchLookupSelectTypeData,
        private readonly dialogRef: MatDialogRef<UserSearchDialogComponent>
    ) {}

    selectUser(userId: string): void {
        this.dialogRef.close(userId);
    }
}
